import React from 'react';
import FileInput from "alpaca.js/dist/forms/file_input";
import Fa from 'alpaca.js/dist/util/fa';


function humanFileSize(bytes) {
    // copied and modified from: https://stackoverflow.com/a/14919494/2761986

    let thresh = 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    let units = ['kB','MB','GB','TB','PB','EB','ZB','YB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
}

export default class CustomFileInput extends FileInput{
    render(){
        const files = _.filter(this.props.files, (f) => f !== undefined);
        let filenames = [];
        let total_file_size = 0;
        let btnStyleClass = `btn ${this.props.btnStyleClass? this.props.btnStyleClass: "btn-outline-primary"} mb-1`;
        for(let index in files) {
            let file = files[index];
            filenames.push(
                <div key={index}>
                    {file.name} ({humanFileSize(file.size)})&#160;
                    <a href="#" className="text-danger" onClick={this.removeFile.bind(this, index)} style={{position:"absolute"}}><Fa icon="times"/></a>
                </div>
            );
            total_file_size += file.size;
        }

        return (
              <div className="file-upload-input">
                  <div className={btnStyleClass}>
                      <span>{this.props.children}</span>
                      <input type="file" onChange={this.handleSelectFile} multiple={this.props.multiple} ref="file_input" />
                  </div>
                  <label>
                      {filenames}
                      {(this.props.fileSizeTotalLimit !== null && total_file_size > this.props.fileSizeTotalLimit) &&
                          <span className="text-danger">
                              <Fa icon="exclamation-triangle" /> Total file size cannot exceed {humanFileSize(this.props.fileSizeTotalLimit)}
                          </span>
                      }
                  </label>
              </div>
        );
    }
}
