import React, {Fragment} from 'react';
import {Button, Card, CardBody, CardTitle, Col, Row,} from "reactstrap";

import {BootstrapDateInput, BootstrapReactSelect, BootstrapText} from "alpaca.js/dist/autoform/bootstrap";
import {HelpPopover} from "./help_popover";
import {Scope} from "informed";
import {string} from "prop-types";

export const RegistrationTab = (props) => {
    function getPlans(store_number) {
        // get the plans for the selected store
        let data = Array();
        if (store_number && props.lookups && props.lookups.store_plans) {
            data = props.lookups.store_plans[store_number];
        }
        if (data && Array.isArray(data) && data.length == 0 || !data) {
            data = Array();
            data.push({value: "", label: "No plans are available for the selected store. Contact Guardian"})
        }
        return data;
    }

    let rent_to_own = props.formState.values.rent_to_own === true;
    let show_shipping = props.formState.values.payment;
    let stores = props.lookups ? props.lookups.stores : Array();
    let plans = getPlans(props.formState.values.store_number);
    show_shipping = !(show_shipping && show_shipping.shipping && show_shipping.shipping.free_in_store_pickup);

    return (
        <Card style={{minHeight: "580px"}}>
            <CardBody>
                <CardTitle><h4>{props.title}</h4></CardTitle>
                <Row>
                    <Col md="3" sm="12">
                        <BootstrapReactSelect
                            field="store_number" label="* Store Number" options={stores}
                        />
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapText field="salesperson_id" label="Sales Person ID"/>
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapText field="invoice_number" type="string" label="* Invoice/Sales Ticket Number"/>
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapDateInput dateFormat="YYYY-MM-DD" field="invoice_date"
                                            label="* Invoice/Purchase Date"/>
                    </Col>
                </Row>
                <Row>
                    <Col md="3" sm="12">
                        <BootstrapText field="transaction_number" label="Transaction Number"
                                       appendText={(<HelpPopover title={"Help"} id="tn"
                                                                 content={"Optional - Enter unique Transaction ID " +
                                                                     "other than " +
                                                                     "Invoice/Sales Ticket Number (ie. Backorder " +
                                                                     "or Split Ticket ID Number)"}/>)}
                        />
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapReactSelect field="online_purchase" label="* Online Purchase"
                                              options={[{value: true, label: "Yes"}, {value: false, label: "No"}]}
                        />
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapReactSelect field="protection_plan_sku" label="* Protection Plan"
                                              options={plans}
                        />
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapText type="number" field="retail_protection_price" label="* Retail Protection Price"/>
                    </Col>
                </Row>
                <Row>
                    <Col md="3" sm="12">
                        <BootstrapText type="number" field="transaction_total"
                                       label="* Total Retail of Covered Items"
                                       appendText={(<HelpPopover title="Help" id="tt"
                                                                 content={"Enter the total retail price of all items " +
                                                                 "to be covered by the Protection Plan " +
                                                                 "before tax or delivery."}/>
                                                    )}
                        />
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapText type="number" field="total_billed_amount" label="* Total Invoice Amount"
                                       appendText={(<HelpPopover title={"Help"} id="tba"
                                                                 content={"Enter the grand total of the invoice to " +
                                                                            "include all items purchased, protection " +
                                                                            "plans, tax and delivery."}/>)}
                        />
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapText type="number" field="tax" label="* Tax"/>
                    </Col>
                </Row>
                <Row>
                    <Col md="3" sm="12">
                        <Scope scope="payment">
                            <Scope scope="shipping">
                                <BootstrapReactSelect field="free_in_store_pickup" label="* Delivery/Pickup"
                                                      options={[{value: false, label: "Delivery"},
                                                          {value: true, label: "In-Store Pickup"}]}/>
                            </Scope>
                        </Scope>
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapDateInput dateFormat="YYYY-MM-DD" field="estimated_delivery_date"
                                            label="* Estimated First Delivery Date"/>
                    </Col>
                    {show_shipping && (
                        <Col md="3" sm="12">
                            <BootstrapText type="number" field="shipping_cost" label="* Shipping/Delivery Amount"
                                           helpText="(charged to the consumer)"/>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col md="3" sm="12">
                        <BootstrapReactSelect field="rent_to_own" label="* Rent To Own Purchase"
                                              options={[{value: true, label: "Yes"},
                                                  {value: false, label: "No"}]}/>
                    </Col>
                    {rent_to_own && (
                        <Fragment>
                            <Col md="3" sm="12">
                                <BootstrapText field="finance_name" label="Finance Company Name"/>
                            </Col>
                            <Col md="3" sm="12">
                                <BootstrapText type="number" field="finance_value" label="Finance Value"/>
                            </Col>
                        </Fragment>
                    )}
                </Row>
            </CardBody>
            <Row>
                <Col md="9"></Col>
                <Col md="1">
                    {props.closeModal &&
                    <Button color="primary" className="mt-4" onClick={props.closeModal}>
                        Close
                    </Button>
                    }
                </Col>
                <Col md="2">
                    <Button color="primary" className="mt-4" onClick={props.submit}>
                        Next >>
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};
