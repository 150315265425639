import React, {Component } from 'react';
import { Row, Col, Button } from "reactstrap";
import axios from 'axios';
import FieldInput from "../forms/field_input";
import CustomFileInput from '../util/customBtnStyleFileInput';
import linkState from "alpaca.js/dist/util/link_state";
import fileDownload from "js-file-download";

export default class RetailerSignup extends Component{
    constructor(props){
        super(props);
        this.submitSignup = this.submitSignup.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.getError = this.getError.bind(this);
        this.downloadApplication = this.downloadApplication.bind(this);
        this.getFormData = this.getFormData.bind(this);

        this.state = {
            user: {},
            retailer: {
                credit_application: [],
            },
            errors: {}
        };
    }

    canSubmit() {
        let user = this.state.user;
        let retailer = this.state.retailer;
        return !(_.trim(user.name) === "" || _.trim(user.email) === "" || _.trim(user.password1) === "" ||
            _.trim(user.password2) === "" || _.trim(retailer.name) === "" || retailer.credit_application.length !== 1);
    }

    downloadApplication(){
        axios.get(this.props.application_url, {responseType: 'arraybuffer'}).then(function (response) {
            let filename = _.split(response.headers['content-disposition'], '=')[1];
            filename = filename ? filename : 'GPP Credit Application Template';
            let mime = response.headers['content-type'];
            fileDownload(response.data, filename, mime);
        });
    }

    getFormData(dict){
        let data = new FormData();
        Object.entries(dict).forEach(entry => {
            let key = entry[0];
            let value = entry[1];
            //use key and value here
            if ( typeof(value) === "object" && key !== "credit_application") {
                data.append(key, this.getFormData(value));
            } else if ( key === "credit_application") {
                data.append(key, value[0]);
            }else {
                data.append(key, value);
            }
        });
        return data;
    }

    submitSignup(){
        let component = this;
        let retailer_data = this.getFormData(this.state.retailer);
        let user_data = this.state.user;
        axios.post(`/api/retailer-signup/`, retailer_data).then((response) => {
            if (response.status === 201){
                // set the retailer id so if there are errors in the user, we don't keep creating retailers
                let retailer = component.state.retailer;
                retailer.id = response.data.id;
                component.setState({retailer: retailer});
                // set the user's retailer group id to relate them to the new retailer
                user_data.retailer_group = response.data.retailer_group_id;
                axios.post(`/api/user-signup/`, user_data).then((response) => {
                    if (response.status === 201){
                        window.location.pathname = response.data.locations_url;
                    }   // should always be 201 or be handled in catch
                }).catch((error) => {
                    component.setState({errors: {user: error.response.data}});
                });
            }   // should always be 201 or be handled in catch
        }).catch((error) => {
                    component.setState({errors: {retailer: error.response.data}});
        });
    }

    getError(section, field){
        return this.state.errors && this.state.errors[section] && this.state.errors[section][field]
    }

    render(){
        return(
            <div className="container">
                <form>
                    <h2 className="mb-3">Retailer Essential Info</h2>
                    <Row>
                        <Col className={"col-sm-6"}>
                            <FieldInput
                                label="Retailer Name"
                                type="text"
                                value={this.state.retailer.name}
                                onChange={linkState(this, "retailer", ["name"])}
                                error={this.getError('retailer', 'name')}
                            />
                        </Col>
                        <Col className={"col-sm-6"}>
                            <FieldInput
                                label="Website URL"
                                type="text"
                                value={this.state.retailer.website_url}
                                onChange={linkState(this, "retailer", ["website_url"])}
                                error={this.getError('retailer', 'website_url')}
                            />
                        </Col>
                    </Row>

                    <Row>

                        <Col className={"col-sm-6"}>
                            <FieldInput
                                label="Email"
                                type="text"
                                value={this.state.retailer.email}
                                onChange={linkState(this, "retailer", ["email"])}
                                error={this.getError('retailer', 'email')}
                            />
                        </Col>

                        <Col className={"col-sm-4"}>
                            <FieldInput
                                label="Phone"
                                type="text"
                                value={this.state.retailer.phone}
                                onChange={linkState(this, "retailer", ["phone"])}
                                error={this.getError('retailer', 'phone')}
                            />
                        </Col>

                        <Col className={"col-sm-2"}>
                            <FieldInput
                                label="Phone Ext."
                                type="text"
                                value={this.state.retailer.phone_extension}
                                onChange={linkState(this, "retailer", ["phone_extension"])}
                                error={this.getError('retailer', 'phone_extension')}
                            />
                        </Col>
                    </Row>

                    <hr className="mt-5 mb-5"/>
                    <h2 className="mb-3">Upload Credit Application</h2>
                    <Row>
                        <div className='col-sm-6'>
                            <Button className='btn-outline-primary' onClick={this.downloadApplication} disabled={this.props.application_url === null} >
                                <i className="fas fa-download" aria-hidden="true"/>
                                Download Credit Application Template
                            </Button>
                        </div>
                        <CustomFileInput
                            multiple={true}
                            type="file"
                            files={this.state.retailer.credit_application}
                            onChange={linkState(this, "retailer", ["credit_application"])}
                            error={this.getError('retailer', 'credit_application')}
                            btnStyleClass='btn-primary'
                        >
                            <i className="fas fa-upload" aria-hidden="true"/>
                            Upload Credit Application
                        </CustomFileInput>


                    </Row>

                    <hr className="mt-5 mb-5"/>
                    <h2 className="mb-3">Primary User Account</h2>
                    <Row>
                        <Col className={"col-sm-6"}>
                            <FieldInput
                                label="Name"
                                type="text"
                                value={this.state.user.name}
                                onChange={linkState(this, "user", ["name"])}
                                error={this.getError('user', 'name')}
                            />
                        </Col>
                        <Col className={"col-sm-6"}>
                            <FieldInput
                                label="Email"
                                type="text"
                                value={this.state.user.email}
                                onChange={linkState(this, "user", ["email"])}
                                error={this.getError('user', 'email')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"col-sm-6"}>
                            <FieldInput
                                label="Password"
                                type="password"
                                value={this.state.user.password1}
                                onChange={linkState(this, "user", ["password1"])}
                                error={this.getError('user', 'password')}
                            />
                        </Col>
                        <Col className={"col-sm-6"}>
                            <FieldInput
                                label="Confirm Password"
                                type="password"
                                value={this.state.user.password2}
                                onChange={linkState(this, "user", ["password2"])}
                                error={this.getError('user', 'password2')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-sm-12 text-center">
                            <Button color="primary" className="mt-4" onClick={this.submitSignup} disabled={!this.canSubmit()}>
                                Finish and Add Locations
                                <i className="fas fa-arrow-right pl-3" aria-hidden="true"/>&#160;
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        )
    }
}
