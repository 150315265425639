import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { setIn } from "immutable-setter";
import VoucherPanel from "./voucher_panel"
import AuthorizationForm from "./authorization_request_form";
import ClaimDetail from "../claims/claim_detail";
import SidePanelWrapper from "../util/side_panel";
import { currency, get_first_letter } from "alpaca.js/dist/util/util";


function sidePanelData(resolution) {
    let quote_request_link = 'N/A';
    if (resolution.quote_request_id) {
        let qr_id = resolution.quote_request_id;
        quote_request_link = (<a href={`/quotes/${qr_id}`} target="_blank">Quote #{qr_id}</a>);
    }
    let plan = resolution.claims[0].plan;
    return [
        [
            {label: "Ticket", value: "#" + resolution.ticket_id},
            {
                label: "Authorization Status",
                value: <span className={`status-${resolution.status}`}>{_.startCase(resolution.status)}</span>
            },
            {
                label: "Customer Name",
                value: <span>
                    <span
                        className="avatar avatar-blank mr-2">{get_first_letter(resolution.customer_name || 'U')}</span>
                    {resolution.customer_name || '[Unknown]'}
                </span>
            },
            {label: "Quote Request", value: quote_request_link},
        ],
        [
            {label: "Plan", value: plan.plan_number ? plan.plan_number : plan},
            {label: "Credit Authorization Date", value: moment(resolution.date_created).format('MM/DD/YYYY') || 'N/A'},
            {
                label: "Total Authorized Amount",
                value: currency(parseFloat(resolution.authorized_amount) + parseFloat(resolution.authorized_delivery_amount || 0.0))
            },
            {label: "Authorized Item Reimb. Amount", value: currency(resolution.authorized_amount) || "N/A"},
            {label: "Authorized Delivery Amount", value: currency(resolution.authorized_delivery_amount) || "N/A"},
            {label: "Authorized Retail Amount", value: currency(resolution.authorized_retail_amount) || "N/A"},
        ],
    ];
}


export default class AuthorizationRequest extends Component {
    constructor(props) {
        super(props);
        this.submitAuthorization = this.submitAuthorization.bind(this);
        this.handleAuthorizationChange = this.handleAuthorizationChange.bind(this);

        this.state = {
            loading: true,
            examination_resolution: null,
            errors: {},
            submitting: false,
            submitting_invoice: false,
        };
    }

    componentDidMount() {
        let component = this;

        axios.get(`/api/examination-resolutions/${component.props.id}/`).then((response) => {
            response.data.authorization = response.data.authorization || {
                "receipt_number": null,
                "redemption_date": null
            };
            component.setState({examination_resolution: response.data, loading: false});
        });
    }

    handleAuthorizationChange(field, e) {
        console.log('handleAuthorizationChange called with' + field);
        const el = e.target;
        const value = el.type === 'checkbox' ? el.checked : el.value;

        this.setState({
            'examination_resolution': setIn(this.state.examination_resolution, ['authorization', field], value),
        });
    }

    submitAuthorization(event, invoice = false) {
        let component = this;
        let request_data = _.pick(this.state.examination_resolution, ['id', 'authorization']);

        if (invoice) {
            this.setState({submitting_invoice: true});
        } else {
            this.setState({submitting: true});
        }

        axios.patch(`/api/examination-resolutions/${component.props.id}/`, request_data).then((response) => {
            let success_url = $('.back-link').attr('href');
            if (invoice) {
                success_url = `/authorizations/${component.props.id}/invoice/`
            }
            window.location.pathname = success_url;
        }).catch(function (error) {
            if (error.response) {
                let errors = JSON.parse(error.response.request.response);
                component.setState({errors: errors});
            } else if (error.request) {
                alert("Unable to communicate with server");
            }
        });
    }

    inReview() {
        if (!this.state.loading) {
            let resolution = this.state.examination_resolution;
            return (resolution.status !== 'pending' || resolution.authorization.id !== undefined)
        }
        return false;
    }

    hasVoucher() {
        return Boolean(this.state.examination_resolution.voucher)
    }

    render() {
        let authorization, errors, amounts, resolution;
        if (!this.state.loading) {
            authorization = this.state.examination_resolution.authorization;
            errors = this.state.errors.authorization || {};
            amounts = {
                authorized_amount: this.state.examination_resolution.authorized_amount,
                authorized_delivery_amount: this.state.examination_resolution.authorized_delivery_amount
            };
            resolution = this.state.examination_resolution;
        }
        return (
            <main>
                {!this.state.loading &&
                <form>
                    <SidePanelWrapper data={sidePanelData(resolution)} back_url="/authorizations/open/">
                        <div className="mb-4">
                            <AuthorizationForm
                                authorization={authorization}
                                amounts={amounts}
                                errors={errors}
                                onChange={this.handleAuthorizationChange}
                                onSubmit={this.submitAuthorization}
                                inReview={this.inReview()}
                                hasVoucher={this.hasVoucher()}
                                submitting={this.state.submitting}
                                submitting_invoice={this.state.submitting_invoice}
                            />
                            {this.state.examination_resolution.voucher ?
                                <VoucherPanel voucher={this.state.examination_resolution.voucher}/> :
                                null
                            }
                        </div>
                        {resolution.notes && (
                        <div>
                            <dt>Notes</dt>
                            <p>{resolution.notes}</p>
                        </div>)}
                        {resolution.claims.map((claim, index) => {
                                return (<Fragment key={`claim_detail_${claim.id}_${index}`}>
                                    <ClaimDetail claim={claim}/>
                                </Fragment>)
                            }
                        )}

                    </SidePanelWrapper>
                </form>
                }
            </main>
        );
    }
}
