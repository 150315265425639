import React, {Component, Fragment} from 'react';
import {Col, Row} from "reactstrap"
import ClaimDamage from "./claim_damage";
import ClaimItem from "./claim_item";
const moment = require('moment');


export default class ClaimDetailExtra extends Component {
    render() {
        let claim = this.props.claim;
        let more_heading = this.props.more_heading;
        return (
            <div className="claim-details">
                {!more_heading && <h4>Claim {claim.name}</h4>}
                <Row>
                    {more_heading &&
                    <Fragment>
                        <Col sm={2}>
                            <h5>Claim {claim.name}</h5>
                        </Col>
                        <Col sm={2}>
                            Ticket #{claim.ticket}
                        </Col>
                    </Fragment>
                    }
                    <Col sm={3}>
                        <strong>Status:</strong> <span className={"status-"+claim.status}>{claim.status}</span>
                    </Col>
                    <Col sm={2}>
                        <strong>Plan:</strong> <a href={this.props.plan_link}
                                                  target="blank">{claim.plan.plan_number}</a>
                    </Col>
                    <Col sm={3}>
                        Last Updated: {moment(claim.date_updated).format('MM/DD/YYYY')}
                    </Col>
                </Row>
                <ClaimItem claim={claim} expanded={this.props.expanded}/>
                <ClaimDamage claim={claim} expanded={this.props.expanded}/>
            </div>
        )
    }
}

