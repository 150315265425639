import React, {Component} from 'react';
import axios from "axios";
import {lineBreaksHtml} from "alpaca.js/dist/util/util";
import ContentWrapper from "../util/contentWrapper";


export default class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            contactInfo: {},
        };
    }

    componentDidMount() {
        axios('/api/contact-info/').then((response) => {
            this.setState({
                loading: false,
                contactInfo: response.data
            });
        });
    }

    renderItem(contactItem) {
        return (
            <div>
                <div className="row">
                    <div className="col-5">
                        <h4 className="mb-0">Phone Number</h4>
                    </div>
                    <div className="col-7">
                        <p className="mb-0">{contactItem.phone_number}</p>
                    </div>
                </div>

                <div className="border-bottom mt-2 mb-2" />

                 <div className="row">
                    <div className="col-5">
                        <h4 className="mb-0">Hours of Operation</h4>
                    </div>
                    <div className="col-7">
                        <p className="mb-0">{lineBreaksHtml(contactItem.hours)}</p>
                    </div>
                </div>

                <div className="border-bottom mt-2 mb-2" />

                <div className="row">
                    <div className="col-5">
                        <h4 className="mb-0">PO Box</h4>
                    </div>
                    <div className="col-7">
                        <p className="mb-0">{lineBreaksHtml(contactItem.address)}</p>
                    </div>
                </div>

                <div className="border-bottom mt-2 mb-2" />

                <div className="row">
                    <div className="col-5">
                        <h4 className="mb-0">Email</h4>
                    </div>
                    <div className="col-7">
                        <p className="mb-0">
                            <a href={`mailto:${contactItem.email}`}>{contactItem.email}</a>
                        </p>
                    </div>
                </div>
                <div className="border-bottom mt-2 mb-2" />
            </div>
        )

    }

    renderItems() {
        // Render the first contact_info item, because for now, only one contact_info per page.
        // may need to change if we need to show multiple. in that case, do a [].forEach() below.
        // This is for you Jonathan. Lov you man.
        const items = this.state.contactInfo.results;
        return (this.renderItem(items[0]))
    }

    render() {
        return (
            <main>
                <ContentWrapper>
                    {this.state.loading ? <i className="fas fa-spinner fa-spin"/> : this.renderItems()}
                </ContentWrapper>
            </main>
        );
    }
}
