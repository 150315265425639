import React, {Fragment} from 'react';
import {Col, Row} from "reactstrap";
import LabeledValue from "../claims/labeled_value";

const LabeledArrayValue = (props) => {
    return (
        <Col sm={props.size || props.sm || "4"} md={props.md}>
            <dt>{props.label}</dt>
            <dd style={props.valueStyle} className={props.valueClass}>
                {props.value.map((s, i) => {
                    return (<Fragment key={i}>
                        <span>{s}</span>
                        <br/>
                    </Fragment>)
                })}
            </dd>
        </Col>
    )
}

const VoucherPanel = (props) => {
    /*jshint ignore:start*/
    return (
        <Row className="mt-4">
            <LabeledValue valueClass={`voucher voucher-${props.voucher.status}`}
                          label="Voucher"
                          value={props.voucher.code}
                          size={3}
            />
            {props.voucher.redemption_amount ?
                <LabeledValue label="Redemption" value={"$" + props.voucher.redemption_amount} size={2}/> : null}
            {props.voucher.shipping_amount ?
                <LabeledValue label="Shipping" value={"$" + props.voucher.shipping_amount} size={2}/> : null}
            <LabeledArrayValue
                label="Voucher Dates"
                value={props.voucher.dates}
                size={4}
            />
        </Row>
    );
    /*jshint ignore:end*/
};

export default VoucherPanel;
