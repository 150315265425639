import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import  { Row, Col } from 'reactstrap';


export default class ContentWrapper extends Component {

    render() {
        let { children } = this.props;

        return (
            <div className="container">
                <Row className="justify-content-center">
                    <Col lg={6} md={8}>
                        {children}
                    </Col>
                </Row>
            </div>
        );
    }
}
