import React from "react";
import {Col, Row} from "reactstrap";
import {Form} from "informed";
import {BootstrapDateInput, BootstrapReactSelect, BootstrapText} from "alpaca.js/dist/autoform/bootstrap";
import {flatten_errors} from "./plan_form";

//
// const validateItems = (formState) => {
//   if (formState){
//       console.log("Form State");
//       console.log(formState);
//       let select_message = "Select all the appropriate item details"
//       if( formState.values.item_category || formState.values.item_type || formState.values.item_material ){
//           let item_fields = formState.values;
//           formState.invalid = true;
//           if(!formState.item_type){
//               formState.errors.item_type = select_message;
//           }else{
//               formState.errors.item_type = undefined;
//           }
//           if(!formState.item_category){
//               formState.errors.item_category = select_message;
//           }else{
//               formState.errors.item_category = undefined;
//           }
//           if(!formState.item_material){
//               formState.errors.item_material = select_message;
//           }else{
//               formState.errors.item_material = undefined;
//           }
//           if(item_fields.item_category && item_fields.item_type && item_fields.item_material){
//               formState.invalid = false;
//               return true;
//           }
//       }
//       return false;
//   }
// };

export const TransactionItemForm = (props) => {
    let formApi;

    function setFormApi(fa) {
        // the modal confirm button needs this to get the form data.
        fa.props_data = props.data;
        props.setFormApi(fa);
        formApi = fa;
        if (props.errors) {
            // The errors need to be applied after the form displays.  I cannot find any way to do this through informed.
            setTimeout(function () {
                handleErrors(props.errors)
            }, 250);
        }
    }

    function getTypes(formState) {
        let data = Array();
        if (formState && formState.values.item_category) {
            let types = props.lookups.category_types[formState.values.item_category];
            if (types){
                for (let type of props.lookups.types) {
                    if (types.indexOf(type.value) >= 0) {
                        data.push(type);
                    }
                }
            }

        }
        return data;
    }

    function handleErrors(errors) {
        debugger;
        let all_errors = flatten_errors(errors);
        for (let error in all_errors) {
            if (formApi.fieldExists(error)) {
                formApi.setError(error, all_errors[error]);
            }
        }
    }
    let all_pick = true;
    if (props.lookups.category_types) {
        return (
            <Form initialValues={props.data} getApi={setFormApi}>
                {({formApi, formState}) => (
                    <Row>
                        <Col md="12" sm="12">
                            <BootstrapText field="description" label="* Description" required/>
                        </Col>
                        <Col md="4" sm="12">
                            <BootstrapDateInput dateFormat="YYYY-MM-DD" field="item_delivery_date"
                                                label="Item Delivery Date"/>
                        </Col>
                        <Col md="4" sm="12">
                            <BootstrapText field="item_model_v_s_n" label="SKU Number"/>
                        </Col>
                        <Col md="4" sm="12">
                            <BootstrapText field="item_transaction_number" label="* Invoice Line Number"
                                           required
                                           helpText={"(Your unique identifier for item)"}/>
                        </Col>
                        <Col md="6" sm="12">
                            <BootstrapText field="manufacturer_name" label="Manufacturer Name"/>
                        </Col>
                        <Col md="6" sm="12">
                            <BootstrapText field="manufacturer_sku" label="* Manufacturer Model Number"
                                           required/>
                        </Col>
                        <Col md="4" sm="12">
                            <BootstrapText type="number" field="quantity"
                                           required
                                           label="* Quantity"/>
                        </Col>
                        <Col md="4" sm="12">
                            <BootstrapText type="number" field="retail_price"
                                           required
                                           label="* Retail Price (Each)"/>
                        </Col>
                        <Col md="4" sm="12">
                            <BootstrapText type="number" field="wholesale_price" label="Wholesale Price"/>
                        </Col>
                        <Col md="6" sm="12">
                            <BootstrapReactSelect field="item_category" label="Item Category"
                                                  options={props.lookups.categories}
                                                  required/>
                        </Col>
                        <Col md="6" sm="12">
                            <BootstrapReactSelect field="item_type" label="Item Type"
                                                  options={getTypes(formState)}
                                                  required/>
                        </Col>
                        <Col md="6" sm="12">
                            <BootstrapReactSelect field="item_material" label="Item Material"
                                                  options={props.lookups.materials}
                                                  required={all_pick}/>
                        </Col>
                        <Col md="6" sm="12">
                            <BootstrapText field="image_url" label="Image URL"/>
                        </Col>
                    </Row>
                )}
            </Form>
        )
    }else{
        return null;
    }
};
