import React, {Fragment} from 'react';
import {Col, Row} from "reactstrap";
import queryString from "query-string";


export const SidePanel = (props) => {
    /*
        Expects a data prop that is an array of "columns" (one or two)
        Each "column" is an array of dicts, each with "label" and "value" keys
        Each dict can optionally contain "labelClass" and "valueClass"
     */

    /* jshint ignore:start */

    const qs = queryString.parse(window.location.search);
    let back_url = props.back_url;
    if (qs.next !== undefined) {
        back_url = qs.next;
    }

    return (
        <Fragment>
            <a href={back_url} className="back-link">Back to Open Items</a>

            <Row>
                {props.data.map((col_data, index) =>
                    <Col md="12" sm="6" xs="12" key={`sp_col${index}`}>
                        {col_data.map((item, item_index) =>
                            <Fragment key={`sp${index}_${item_index}`}>
                                <dt>{item.label}</dt>
                                <dd>{item.value}</dd>
                            </Fragment>
                        )}
                    </Col>
                )}
            </Row>
            </Fragment>
    );
    /* jshint ignore:end */
};

const SidePanelWrapper = (props) => {

    /* jshint ignore:start */
    return (
        <Row className="mt-4">
            <Col md="3" sm="12" >
                <SidePanel data={props.data} back_url={props.back_url} />
            </Col>
            <Col md="9" sm="12">
                {props.children}
            </Col>
        </Row>
    );
    /* jshint ignore:end */
};


export default SidePanelWrapper;
