import React, {Fragment} from 'react';
import {Scope,} from 'informed';
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {CustomerInfo} from "./customer_info"
import {BootstrapCheckbox, BootstrapReactSelect} from "alpaca.js/dist/autoform/bootstrap";

export const ContactTab = (props) => {
    let show_billing = props.formState.values.payment;
    show_billing = !(show_billing && show_billing.billing && show_billing.billing.pay_same_as_ship);
    return (
        <Card style={{minHeight: "580px"}}>
            <CardBody>
                <CardTitle>
                    <h4>{props.title}</h4>
                </CardTitle>
                <hr/>
                <CardTitle><h5>Shipping Info</h5>(Customer's delivery address/place where the covered items are located)</CardTitle>
                <Row>
                    <Col md="12" sm="12">
                        <Scope scope="payment">
                            <Scope scope="billing">
                                <BootstrapCheckbox field="pay_same_as_ship" label="Billing Info Same As Shipping"/>
                            </Scope>
                        </Scope>
                    </Col>
                </Row>
                <Scope scope="payment">
                    <Scope scope="shipping">
                        <Scope scope="customer_info">
                            <CustomerInfo lookups={props.lookups}/>
                        </Scope>
                    </Scope>
                </Scope>
                {show_billing && (
                    <Fragment>
                        <Scope scope="payment">
                            <Scope scope="billing">
                                <hr/>
                                <CardTitle><h5>Billing Info</h5>
                                    (Customer's billing/mailing address)
                                </CardTitle>
                                <Scope scope="customer_info">
                                    <CustomerInfo lookups={props.lookups}/>
                                </Scope>
                            </Scope>
                        </Scope>
                    </Fragment>
                )}
                <hr/>
                <Row>
                    <Col md="3" sm="12">
                        <BootstrapCheckbox field="opt_in_email" label="Opt-in Email"/>
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapCheckbox field="opt_in_mobile" label="Opt-in Mobile"/>
                    </Col>

                    <Col md="3" sm="12">
                        <BootstrapCheckbox field="electronic_terms" label="Electronic Delivery"
                                           helpText={"( of terms and conditions )"}/>
                    </Col>
                    <Col md="3" sm="12">
                        <BootstrapReactSelect field="locale" label="Terms and Conditions Language"
                                              options={[{value: "EN", label: "English"}]}
                        />
                    </Col>
                </Row>
            </CardBody>
            <Row>
                <Col md="10">
                </Col>
                <Col md="2">
                    <Button color="primary" className="mt-4" onClick={function(event){ props.submit();}}>
                        Submit
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};
