import React from 'react';
import PropTypes from 'prop-types';
import { PaginationLink, PaginationItem } from 'reactstrap';


export class PaginatorButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        const { onSelect, disabled, eventKey } = this.props;

        event.preventDefault();

        if (disabled) {
            return;
        }

        if (onSelect) {
          onSelect(eventKey, event);
        }
    }

    render() {
        return (
            <PaginationItem disabled={this.props.disabled} active={this.props.active}>
                <PaginationLink onClick={this.handleClick} href="#">
                    {this.props.children}
                </PaginationLink>
            </PaginationItem>
        );
    }
}

PaginatorButton.propTypes = {
    eventKey: PropTypes.number,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
};

PaginatorButton.defaultProps = {
    disabled: false,
    active: false,
};
