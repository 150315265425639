import React, { Component, Fragment } from 'react';
import { Table } from "reactstrap";
import ClaimDetail from "../claims/claim_detail";
import { currency, get_first_letter } from 'alpaca.js/dist/util/util';
import SidePanelWrapper from "../util/side_panel";
import { withAPIData } from "alpaca.js/dist/api/withAPIData";
import LoadingWrapper from "alpaca.js/dist/util/loadingWrapper";
import { RetailerInteractionsPanel } from "../interactions/retailer_interactions_panel";
import { Documentation } from "alpaca.js/dist/bulk_upload/documentation";

import axios from "axios/index";
import { crmClient } from "../crmapi";

const LineItemsTable = ({invoice, children}) => {
    return (
        <Fragment>
            <h4>Line Items</h4>
            <Table className="mb-1">
                <thead>
                <tr>
                    <th>Description</th>
                    <th>Amount Requested</th>
                    <th>Amount Paid</th>
                </tr>
                </thead>
                <tbody>
                {children}
                </tbody>
                <tfoot>
                <tr>
                    <th>Totals</th>
                    <th>{currency(invoice.requested_amount)}</th>
                    <th>{currency(invoice.approved_amount)}</th>
                </tr>
                </tfoot>

            </Table>
        </Fragment>
    );
};

function sidePanelData(data) {
    let plan = data.claims[0].plan;
    let customer_name = data.claims[0].contact.name;

    return [
        [
            {label: "Invoice", value: `#${data.invoice_number}`},
            {label: "Ticket", value: `#${data.claims[0].ticket}`},
            {
                label: "Invoice Status",
                value: <span
                    className={`status-${data.status}`}>{_.startCase(data.status_display)} {data.status_date && moment(data.status_date).format('MM/DD/YYYY')}</span>
            },
        ],
        [
            {
                label: "Customer Name",
                value: <span>
                    <span className="avatar avatar-blank mr-2">{get_first_letter(customer_name || 'U')}</span>
                    {customer_name || '[Unknown]'}
                </span>
            },
            {label: "Plan", value: plan.plan_number},
        ],
    ];
}

export default withAPIData(class InvoiceDetail extends Component {
    constructor(props) {
        super(props);
        // this.submit = this.submit.bind(this);

        this.state = {
            errors: {},
            documentation: null
        };
        this.documentationUploaded = this.documentationUploaded.bind(this);
    }

    // this is required because withAPIData breaks the react lifecycle....
    getDocumentation() {
        return this.state.documentation || this.props.data.documentation;
    }

    canSubmit() {
        let invoice = this.props.data;
        let loading = this.props.loading;

        // retail and wholesale price for every quote request claim must be provided
        // any available parts must have time frame and price fields
        if (loading || this.inReview()) {
            return false;
        }

        return (
            (_.trim(invoice.requested_amount) !== "") &&
            (_.trim(invoice.invoice_number) !== ""));
    }

    inReview() {
        return (!loading && invoice.date_submitted !== null);
    }

    renderLineItems(items) {
        return (
            <LineItemsTable invoice={this.props.data}>
                {items.map((item, index) =>
                    <tr key={`inv_line_${index}`}>
                        <td>{item.description}</td>
                        <td>{currency(item.requested_amount)}</td>
                        <td>{currency(item.approved_amount)}</td>
                    </tr>
                )}
            </LineItemsTable>
        );
    }

    documentationUploaded(fileData) {
        let data = {
            "documentation_ids": []
        };
        let headers = {headers: {"X-CSRFToken": Cookies.get("csrftoken")}};
        let documentation = this.getDocumentation();

        console.log(headers)

        for (let i = 0; i < documentation.length; i++) {
            data["documentation_ids"].push({"id": documentation[i].id})
        }
        data["documentation_ids"].push({"id": fileData.id})

        this.setState({
            uploading: this.state.uploading - 1
        });
        crmClient.put(`/invoices/${this.props.data.crm_id}/attach/`, data, headers).then(result => {
            this.setState({
                documentation: result.data
            })
            axios.put("/invoices/sync/", [])
        });
    }

    render() {
        let invoice = this.props.data;

        crmClient.defaults.xsrfHeaderName = "X-CSRFToken";
        crmClient.defaults.xsrfCookieName = "csrftoken";
        return (
            <LoadingWrapper loading={this.props.loading}>{() => (
                <SidePanelWrapper data={sidePanelData(invoice)} back_url="/invoices/open/">
                    <div className="mb-3">
                        {invoice.claims.map((claim, index) =>
                            <ClaimDetail key={"claim_" + index} claim={claim} show_item={false}/>
                        )}
                    </div>


                    <div className="row mb-3">
                        {invoice.portal_notes && (
                            <div className="col-6">
                                <h4>My Notes</h4>
                                <p>{invoice.portal_notes}</p>
                            </div>
                        )}

                        {invoice.crm_notes && (
                            <div className="col-6">
                                <h4>Guardian's Notes</h4>
                                <p>{invoice.crm_notes}</p>
                            </div>
                        )}
                    </div>

                    {this.renderLineItems(invoice.line_items)}
                    <Documentation
                        documentation={this.getDocumentation()}
                        crmClient={crmClient}
                        uploadUrl="/invoice_documentation/"
                        onFileUploaded={this.documentationUploaded}
                    />
                    <RetailerInteractionsPanel ticket_id={invoice.claims[0].ticket}
                                               retailer_id={invoice.claims[0].plan.retailer.id} no_padding={true}/>
                </SidePanelWrapper>
            )}</LoadingWrapper>

        );
    }
}, '/api/invoices/${id}/')
