import React from "react";
import {Col, Row, Button} from "reactstrap";
import {Form} from "informed";
import {BootstrapText} from "alpaca.js/dist/autoform/bootstrap";
import {flatten_errors} from "./plan_form";
import {HelpPopover} from "./help_popover";

export const ReturnItemForm = (props) => {
    let formApi;

    function setFormApi(fa) {
        // the modal confirm button needs this to get the form data.
        fa.props_data = props.data;
        props.setFormApi(fa);
        formApi = fa;
        // The errors need to be applied after the form displays.  I cannot find any way to do this through informed.
        setTimeout(function () {
            handleErrors(props.errors)
        }, 250);
    }

    function handleErrors(errors) {
        let all_errors = flatten_errors(errors);
        for (let error in all_errors) {
            if (formApi.fieldExists(error)) {
                formApi.setError(error, all_errors[error]);
            }
        }
    }

    return (
        <Form getApi={setFormApi}>
            <Row>
                <Col md="12" sm="12">
                    <BootstrapText field="return_invoice" label="* Invoice Number"
                                   appendText={(<HelpPopover title={"Help"} id="tn"
                                                             content={"Enter credit memo or return number. If not " +
                                                                "available or applicable, enter original invoice " +
                                                                "number."}/>)}
                    />
                </Col>
            </Row>
        </Form>
    )
};
