import React, {Component, Fragment} from 'react';
import {Col, Row} from 'reactstrap';
import JWT from "../jwt_refresh";
import axios from "axios";
import {flatten_errors} from "./plan_form"
import {ModalButton} from "./modal_button";
import {TransactionItemForm} from "./transaction_item_form";
import {ReturnItemForm} from "./return_item_form";
import {getLookups} from "./lookups";

const DisplayField = (props) => {
    if (props.data === undefined || props.field === undefined) {
        return null;
    }
    let value = props.data[props.field];
    if (typeof value == 'boolean') {
        value = value ? (<span className={"fa fa-check"}></span>) : (<span className={"fa fa-times"}></span>);
    }
    return (
        <div className="mt-2 mb-1">
            {value}
        </div>
    );
};

const ItemRow = (props) => {
    /* display a row, and buttons for editing and deleting */
    let data = props.data;
    let OddOrEven = (props.row_number & 1) ? "odd" : "even";

    function returnItem(modal) {
        props.returnItem(modal, data);
    }

    function saveItem(modal) {
        props.saveModal(modal, data);
    }
    if (data) {
        return (
            <Fragment>
                <Row className={OddOrEven}>
                    <Col sm="4">
                        <DisplayField field="description" data={data}/>
                    </Col>
                    <Col sm="1" className={"text-right"}>
                        <DisplayField field="retail_price" data={data}/>
                    </Col>
                    <Col sm="1" className={"text-center"}>
                        <DisplayField field="fulfilled" data={data}/>
                    </Col>
                    <Col sm="1" className={"text-center"}>
                        <DisplayField field="returned_item" data={data}/>
                    </Col>
                    <Col sm="2" className={"text-center"}>
                        <DisplayField field="return_invoice" data={data}/>
                    </Col>
                    <Col sm="3" className={"text-right"}>
                        <div className="transaction-item-actions mt-2">
                            {/* EDIT item form */}
                            {props.editable && !data.fulfilled && !data.returned_item &&
                            <Fragment>
                                <ModalButton outline color="primary" className="btn btn-xs mb-1"
                                             action={saveItem}
                                             title={"Edit Transaction Item"}
                                             action_label="Save"
                                             label="Edit"
                                             modal_style={{maxWidth: "700px"}}
                                             cancelAction={props.cancelModal}
                                             props_data={data}
                                >
                                    <h5 style={{color: "red"}}>{props.status_error}</h5>
                                    {props.form_errors &&
                                    <ul>
                                        {Object.keys(props.form_errors).map((k) => (
                                            <li key={k}>{k}: {props.form_errors[k]}</li>
                                        ))}
                                    </ul>}
                                    <TransactionItemForm data={data} errors={props.errors}
                                                         setFormApi={props.setModalFormApi}
                                                         lookups={props.lookups}
                                    />
                                </ModalButton> &nbsp;
                                {/*RETURN item*/}
                                {props.editable && !data.returned_item &&
                                    <ModalButton outline color="danger" className="btn btn-xs mr-1 mb-1"
                                                 action={returnItem}
                                                 title="Return Transaction Item"
                                                 label="Cancel/Return"
                                                 action_label={"Return Item"}
                                                 cancelAction={props.cancelModal}>
                                        <h5 style={{color: "red"}}>{props.status_error}</h5>
                                        {props.form_errors &&
                                            <ul>
                                                {Object.keys(props.form_errors).map((k) => (
                                                    <li key={k}>{k}: {props.form_errors[k]}</li>
                                                ))}
                                            </ul>}
                                        <ReturnItemForm
                                            setFormApi={props.setModalFormApi}
                                        />
                                    </ModalButton>
                                }
                            </Fragment>
                            }
                        </div>
                    </Col>
                </Row>
            </Fragment>
        )
    } else {
        return null;
    }
}


export default class PlanItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editable: false,
            lookups: {},
            status_error: null,
            form_errors: null
        };
        // make auto refreshed token object
        this.jwt = null;
        if (props.access_token) {
            this.jwt = new JWT({access_token: props.access_token, refresh_token: props.refresh_token});
        }
        this.saveAddModal = this.saveAddModal.bind(this);
        this.saveEditModal = this.saveEditModal.bind(this);
        this.returnItem = this.returnItem.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.setModalFormApi = this.setModalFormApi.bind(this);
        this.handleErrors = this.handleErrors.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.getLookups = getLookups.bind(this);

    }

    checkEditable(data) {
        let editable = false;
        if (this.jwt && data.can_edit) {
            let one_year = new Date(`${this.props.data["purchase_date"]}T00:00`);
            one_year.setFullYear(one_year.getFullYear() + 1);
            if (new Date() < one_year) {
                editable = true
            }
        }
        return editable
    }

    componentDidMount() {
        this.getLookups();
    }

    clearErrors() {
        // manually clear the errors
        let errors = flatten_errors(this.modalFormApi.getState().errors);
        for (let error in errors) {
            this.modalFormApi.setError(error, undefined);
        }
        this.setState({
            errors: {},
            status_error: null,
            form_errors: null
        });
    }

    handleErrors(errors) {
        debugger;
        let all_errors = flatten_errors(errors);
        let form_errors = Object();
        for (let error in all_errors) {
            if (this.modalFormApi.fieldExists(error)) {
                this.modalFormApi.setError(error, all_errors[error]);
            } else {
                form_errors[error] = all_errors[error];
            }
        }
        this.setState({
            "form_errors": form_errors
        })
    }

    setModalFormApi(modalFormApi) {
        this.modalFormApi = modalFormApi;
    }

    cancelModal(modalform) {
        this.clearErrors();
        modalform.toggleModal();
    }

    returnItem(modalform, data) {
        let form_data = this.modalFormApi.getState().values;
        let returned_item = {
            item_transaction_number: data["item_transaction_number"],
            manufacturer_sku: data["manufacturer_sku"],
            quantity: 1,
            return_invoice: form_data["return_invoice"],
            db_id: data.id
        //    Add filter for if db_id then Return, if no db_id the Cancel
        }
        axios.post(this.props.item_return_url, returned_item, {headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}}).then((response) => {
            modalform.toggleModal();
            window.location.reload();
        }).catch((error) => {
            this.setState({status_error: error.response.data.title});
            this.handleErrors(error.response.data.errors);
        })
    }

    saveEditModal(modalform, props_data) {
        debugger;
        let data = this.modalFormApi.getState().values;
        this.clearErrors();
        axios.post(this.props.item_edit_url + String(props_data.id), data, {headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}}).then((response) => {
            modalform.toggleModal();
            window.location.reload();
            // if (response.data.warnings) { // Suppressing warnings being displayed
            //     this.handleErrors(response.data.warnings);
            // } else {
            //     modalform.toggleModal();
            // }
        }).catch((error) => {
            this.setState({status_error: error.response.data.title});
            this.handleErrors(error.response.data.errors);
        })
    }

    saveAddModal(modalform) {
        debugger;
        let data = this.modalFormApi.getState().values;
        this.clearErrors();
        axios.post(this.props.item_add_url, data, {headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}}).then((response) => {
            this.setState({
                status_error: response.data.title,
            });
            modalform.toggleModal();
            window.location.reload();
        }).catch((error) => {
            this.setState({status_error: error.response.data.title});
            this.handleErrors(error.response.data.errors);
        })
    }


    render() {
        let data = this.props.data;
        let editable = this.checkEditable(data);

        return (
            <div className="plan-edit-form">
                <Row>
                    <Col className="pb-2" sm="6"><h4>Items on Plan</h4>
                    </Col>
                    <Col>
                        {this.state.form_errors &&
                        <ul style={{color: "red"}}>
                            {Object.keys(this.state.form_errors).map((k) => (
                                <li key={k}>{k}: {this.state.form_errors[k]}</li>
                            ))}
                        </ul>}
                    </Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <h5>Description</h5>
                    </Col>
                    <Col sm="1" className={"text-right"}>
                        <h5>Retail Price</h5>
                    </Col>
                    <Col sm="1" className={"text-center"}>
                        <h5>Fulfilled</h5>
                    </Col>
                    <Col sm="1" className={"text-center"}>
                        <h5>Returned</h5>
                    </Col>
                    <Col sm="2" className={"text-center"}>
                        <h5>Return Invoice</h5>
                    </Col>
                    <Col sm="3" className={"text-right"}>
                        {/* ADD item form */}
                        {editable && <ModalButton outline color="info" className="btn btn-xs mb-1"
                                                             action={this.saveAddModal} title="Add Item"
                                                             label="Add Item" action_label="Save"
                                                             modal_style={{maxWidth: "700px"}}
                                                             cancelAction={this.cancelModal}
                                                             props_data={data}
                        >
                            <h5 style={{color: "red"}}>{this.state.status_error}</h5>
                            {this.state.form_errors &&
                            <ul>
                                {Object.keys(this.state.form_errors).map((k) => (
                                    <li key={k}>{k}: {this.state.form_errors[k]}</li>
                                ))}
                            </ul>}
                            <TransactionItemForm data={data}
                                                 onSubmit={this.saveAddModal}
                                                 setFormApi={this.setModalFormApi}
                                                 lookups={this.state.lookups}
                            />
                        </ModalButton>}
                    </Col>
                </Row>
                {data.items.map((item, index) => (
                    <ItemRow key={index} row_number={index} data={item}
                                               errors={this.state.errors}
                                               form_errors={this.state.form_errors}
                                               status_error={this.state.status_error}
                                               lookups={this.state.lookups}
                                               item_edit_url={this.props.item_edit_url}
                                               item_return_url={this.props.item_return_url}
                                               cancelModal={this.cancelModal}
                                               saveModal={this.saveEditModal}
                                               returnItem={this.returnItem}
                                               setModalFormApi={this.setModalFormApi}
                                               handleErrors={this.handleErrors}
                                               clearErrors={this.clearErrors}
                                               editable={editable}

                    />
                ))}
            </div>
        )
    }
}
