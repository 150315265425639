import React, {Component, Fragment} from 'react';
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import axios from 'axios';
import { QuoteRequestClaim } from './quote_request_claim';
import { setIn } from 'immutable-setter';
import FieldInput from "../forms/field_input";
import linkState from 'alpaca.js/dist/util/link_state';
import ClaimDetail from "../claims/claim_detail";
import LabeledValue from "../claims/labeled_value";
import SidePanelWrapper from "../util/side_panel";
import {currency, get_first_letter} from "alpaca.js/dist/util/util";


function sidePanelData(quote_request) {

    let claim = quote_request.quote_request_claims[0].claim;

    return [
        [
            {label: "Ticket", value: `#${claim.ticket}`},
            {
                label: "Quote Request Status",
                value: <span className={`status-${quote_request.status}`}>{_.startCase(quote_request.status)}</span>
            },
        ],
        [
            {
                label: "Customer Name",
                value: <span>
                    <span className="avatar avatar-blank mr-2">{get_first_letter(claim.contact.name || 'U')}</span>
                    {claim.contact.name || '[Unknown]'}
                </span>
            },
            {label: "Plan", value: claim.plan.plan_number},
        ],
    ];
}

export default class QuoteRequest extends Component {
    constructor(props) {
        super(props);
        this.handleClaimChange = this.handleClaimChange.bind(this);
        this.handlePartChange = this.handlePartChange.bind(this);
        this.changeResponseNote = this.changeResponseNote.bind(this);
        this.submitClaim = this.submitClaim.bind(this);

        this.state = {
            loading: true,
            quote_request: null,
            errors: {}
        };
    }

    componentDidMount() {
        let component = this;

        axios.get(`/api/quote-request/${component.props.quote_request_id}/`).then((response) => {
            component.setState({quote_request: this.setInitialDefaults(response.data), loading: false});
        });
    }

    setInitialDefaults(data) {
        if (data.status === "pending") {
            for(let i=0; i < data.quote_request_claims.length; ++i) {
                let qrc = data.quote_request_claims[i];
                qrc.item_available = true;
                qrc.customer_paid_delivery = false;

                let item = qrc.claim.item;
                if (item) {
                    qrc.item_retail_price = (item.retail_price && item.retail_price > 0) ? item.retail_price : '';
                    qrc.item_wholesale_price = (item.wholesale_price && item.wholesale_price > 0) ? item.wholesale_price : '';
                }
                for (let p=0; p < qrc.parts.length; p++) {
                    qrc.parts[p].available = true;
                }
            }
        }
        return data
    }

    valueFromEventTarget(evt) {
        const el = evt.target;
        if (el.type === 'checkbox') {
            return el.checked;
        } else if (el.type === 'radio') {
            return el.value === 'true';
        }
        return el.value;
    }

    handleClaimChange(index, field, evt) {
        this.setState({
            'quote_request': setIn(
                this.state.quote_request,
                ['quote_request_claims', index, field], this.valueFromEventTarget(evt)
            ),
        });
    }

    handlePartChange(quote_request_claim_index, part_index, field, evt) {
        this.setState({
            'quote_request': setIn(
                this.state.quote_request,
                ['quote_request_claims', quote_request_claim_index, 'parts', part_index, field],
                this.valueFromEventTarget(evt)
            ),
        });
    }

    canSubmit() {
        // for authorizations a value for the primary price type must be provided
        // any available parts must have time frame and price fields
        if (this.state.loading || this.inReview()) {
            return false;
        }
        let qr = this.state.quote_request;
        let primary_price = qr.primary_price || 'retail';
        for(let index in qr.quote_request_claims) {
            if (qr.quote_request_claims.hasOwnProperty(index)) {
                let qrc = qr.quote_request_claims[index];
                if ((primary_price === 'retail' && _.trim(qrc.item_retail_price) === "" )
                    || (primary_price !== 'retail' && _.trim(qrc.item_wholesale_price) === "")) {
                    return false;
                }

                for(let part_index in qrc.parts) {
                    if (qrc.parts.hasOwnProperty(part_index)) {
                        let part = qrc.parts[part_index];

                        if (part.available) {
                            if (_.trim(part.part_number) === "" || _.trim(part.wholesale_price) === "" || _.trim(part.delivery_price) === "") {
                                return false;
                            }
                        }
                    }
                }

            }
        }
        return true;
    }

    inReview() {
        return (!this.state.loading && this.state.quote_request.status !== "pending");
    }

    submitClaim() {
        // console.log('Submit claim button clicked');
        let component = this;
        let qr = this.state.quote_request;

        let request_data = {
            'response_notes': qr.response_notes,
            'quote_request_claims': []
        };

        this.state.quote_request.quote_request_claims.map(qrc => {
            let qrc_data = _.pick(qrc, ['id', 'item_available', 'available_time_frame', 'customer_paid_delivery',
                'item_delivery_price', 'parts']);
            qrc_data['item_retail_price'] = (qrc.item_retail_price) ? qrc.item_retail_price : null;
            qrc_data['item_wholesale_price'] = (qrc.item_wholesale_price) ? qrc.item_wholesale_price : null;
            request_data.quote_request_claims.push(qrc_data);
        });

        // console.log("Quote Response Data", request_data);

        axios.patch(`/api/quote-request/${component.props.quote_request_id}/`, request_data).then((response) => {
            window.location.pathname = $('.back-link').attr('href');
        });
    }

    changeResponseNote(event) {
        // console.log(event.target);
        this.setState({response_notes: event.target.value});
    }

    renderRequestForm() {
        // Renders the response notes and submit button for the quote request form
        /*jshint ignore:start*/
        return (
            <Row className="mt-4">
                <Col className="col-sm-7">
                    <FieldInput
                        label="Notes"
                        type="textarea"
                        value={this.state.quote_request.response_notes}
                        onChange={linkState(this, "quote_request", ["response_notes"])}
                        readOnly={this.inReview()}
                    />
                </Col>
                {!this.inReview() &&
                    <Col className="col-sm-5">
                        <Button color="primary" className="mt-4" onClick={this.submitClaim} disabled={!this.canSubmit()}>
                            Submit Quote
                        </Button>
                    </Col>
                }
            </Row>
        );
        /*jshint ignore:end*/
    }

    render() {
        let quote_request = this.state.quote_request;

        /*jshint ignore:start*/
        return (
            <main>
                {!this.state.loading &&
                    <div>
                        <form>
                            <SidePanelWrapper data={sidePanelData(quote_request)} back_url="/quotes/open/">
                                {quote_request.quote_request_claims.map((quote_request_claim, index) =>
                                    <Fragment key={`qrc_${quote_request_claim.id}`}>
                                        <ClaimDetail claim={quote_request_claim.claim}/>
                                        <LabeledValue label="Notes" value={quote_request.request_notes}/>
                                        <QuoteRequestClaim
                                            quote_request_claim={quote_request_claim}
                                            quote_request_claim_index={index}
                                            delivery_optional={quote_request.delivery_optional}
                                            onChange={this.handleClaimChange}
                                            onPartChange={this.handlePartChange}
                                            readOnly={this.inReview()}
                                        />
                                    </Fragment>
                                )}

                                {this.renderRequestForm()}
                            </SidePanelWrapper>
                        </form>
                    </div>
                }
            </main>
        );
        /*jshint ignore:end*/
    }
}
