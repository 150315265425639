import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import {BootstrapText, BootstrapReactSelect} from "alpaca.js/dist/autoform/bootstrap";

export const CustomerInfo = (props) => {
    let state_choices = props.lookups && props.lookups.state_choices || Array();
    return (
        <Fragment>
            <Row>
                <Col md="3" sm="12">
                    <BootstrapText field="first_name" label="* First Name"/>
                </Col>
                <Col md="3" sm="12">
                    <BootstrapText field="last_name" label="* Last Name"/>
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="12">
                    <BootstrapText field="address_1" label="* Address 1"/>
                </Col>
                <Col md="4" sm="12">
                    <BootstrapText field="address_2" label="Address 2"/>
                </Col>
            </Row>
            <Row>
                <Col md="3" sm="12">
                    <BootstrapText field="city" label="* City"/>
                </Col>
                <Col md="3" sm="12">
                    <BootstrapReactSelect field="state" label="* State" options={state_choices}/>
                </Col>
                <Col md="3" sm="12">
                    <BootstrapText field="zip" label="* Zip"/>
                </Col>
            </Row>
            <Row>
                <Col md="3" sm="12">
                    <BootstrapText field="email" label="Email"/>
                </Col>
                <Col md="3" sm="12">
                    <BootstrapText field="mobile_phone" label="Mobile Phone"/>
                </Col>
                <Col md="3" sm="12">
                    <BootstrapText field="home_phone" label="Home Phone"/>
                </Col>
                <Col md="3" sm="12">
                    <BootstrapText field="work_phone" label="Work Phone"/>
                </Col>
            </Row>
        </Fragment>
    )
};
