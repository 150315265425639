import axios from "axios";
import {addAxiosErrorHandling} from "alpaca.js/dist/api/axios";


export const crmClient = axios.create({
    baseURL: JSVARS.crm_api_url,
});

async function refreshToken() {
    try {
        let response = await axios.post('/api/refresh-token/');

        if(response.data.token === null) {
            document.location = '/';
            return null;
        }

        JSVARS.crm_api_token = response.data.token;
        return JSVARS.crm_api_token;
    } catch(error) {
        return null;
    }
}

function addToken(config, token) {
    let originalConfig = config;

    if(!_.has(originalConfig.headers, 'Authorization')) {
        if(token == null) {
            return Promise.reject();
        }

        originalConfig.headers['Authorization'] = `Token ${token}`;
        return Promise.resolve(originalConfig);
    } else {
        return config;
    }
}

crmClient.interceptors.request.use((config) => addToken(config, JSVARS.crm_api_token));

crmClient.interceptors.response.use(undefined, (error) => {
    if(!error.response){
        return Promise.reject(error);
    } else if(error.response.status === 403 && error.response.data.detail === 'Invalid token.') {
        return refreshToken().then((token => {
            let originalConfig = error.config;

            if(token != null && !_.has(originalConfig.headers, 'Authorization') || !_.includes(originalConfig.headers['Authorization'], token)) {
                originalConfig.headers['Authorization'] = `Token ${token}`;
                return crmClient(originalConfig);
            }

            return Promise.reject(error);
        }));
    } else if(error.response.status === 403 || error.response.status === 404) {
        document.location = '/404/';
    }

    return Promise.reject(error);
});


addAxiosErrorHandling(crmClient, 'sentry_id', '/');
