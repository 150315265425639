import React, { Component, Fragment } from 'react';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import axios from "axios/index";
import {crmClient} from "./crmapi";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export class AttachmentViewer extends Component {
    constructor(props) {
        super(props);
        this.goToNext = this.goToNext.bind(this);
        this.goToPrev = this.goToPrev.bind(this);
        this.updateUrl = this.updateUrl.bind(this);

        this.state = {
            isOpen: false,
            attachments: _.groupBy(this.props.attachments, ((attachment) => attachment.attachment_type)),
            attachment_urls: this.props.attachments.reduce(
                function(map, obj) {
                    map[obj.id] = {url: null, expires: null};
                    return map;
                }, {}),
        };
    }

    needToUpdateUrl(index, openSection=null){
        if ( openSection === null ) {
            openSection = this.state.open_section;
        }
        let attachment_url = this.state.attachment_urls[this.state.attachments[openSection][index].id];
        return attachment_url.url === null || attachment_url.expires < new Date();
    }


    updateUrl(attachment, index){
        let component = this;
        return crmClient.get(`${this.props.claimUrl.substring(1)}view_attachment/?attachment=${attachment['id']}`).then(function (response) {
            let newState = { isOpen: true, open_section: attachment.attachment_type, open_index: index};
            if (response.status === 200){
                let updatedUrls = component.state.attachment_urls;
                updatedUrls[attachment.id] = response.data;

                let updatedAttachments = component.state.attachments;
                updatedAttachments[attachment.attachment_type][index].attachment = response.data.url;

                component.setState({attachment_urls: updatedUrls, attachments: updatedAttachments});
            }
            component.setState(newState);
        });
    }

    openImage(attachment, index) {
        // if we don't have a valid url for the image, get it from the CRM
        if(this.needToUpdateUrl(index, attachment.attachment_type)){
            this.updateUrl(attachment, index).then((success)=>{
                this.setState({ isOpen: true, open_section: attachment.attachment_type, open_index: index});
            });
        } else {
            this.setState({ isOpen: true, open_section: attachment.attachment_type, open_index: index});
        }
    }

    getSrc(index) {
        let attachment = this.state.attachments[this.state.open_section][index];
        if(this.needToUpdateUrl(index)){
            this.updateUrl(attachment, index);
        }
        return this.state.attachments[this.state.open_section][index].attachment;
    }

    getMainSrc() {
        return this.getSrc(this.state.open_index);
    }

    getPrevSrc() {
        return this.getSrc(this.getPrevIndex());
    }

    getNextSrc() {
        return this.getSrc(this.getNextIndex());
    }

    getNextIndex() {
        let count = this.state.attachments[this.state.open_section].length;
        return (this.state.open_index + count - 1) % count;
    }

    getPrevIndex() {
        let count = this.state.attachments[this.state.open_section].length;
        return (this.state.open_index + 1) % count;
    }

    goToNext() {
        this.setState({open_index: this.getNextIndex()});
    }

    goToPrev() {
        this.setState({open_index: this.getPrevIndex()});
    }

    render() {
        return (
            <div>
                {_.map(this.state.attachments, (attachments, attachment_type) =>
                    <div className="d-inline-block mr-3" key={attachment_type}>
                        <h6>{attachment_type === 'receipt' ? "Receipts" : "Photos"}</h6>

                        {_.map(attachments, (attachment, index) =>
                            attachment.thumbnail !== null ? (
                                <a className="thumbnail" onClick={this.openImage.bind(this, attachment, index)} style={{cursor: "pointer"}} key={attachment.id}>
                                    <img src={attachment.thumbnail} alt="Claim file attachment"/>
                                </a>
                            ) : (
                                <a className="thumbnail" target="_blank" href={attachment.attachment} key={attachment.id}>
                                    <i className="fas fa-file" aria-hidden="true"/>
                                </a>
                            )
                        )}
                    </div>
                )}

                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.getMainSrc()}
                        nextSrc={this.getNextSrc()}
                        prevSrc={this.getPrevSrc()}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={this.goToNext}
                        onMoveNextRequest={this.goToPrev}
                        reactModalStyle={{overlay: {zIndex: 1110}}}
                    />
                )}
            </div>
        );
    }
}

