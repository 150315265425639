import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

export class ModalButton extends React.Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.doAction = this.doAction.bind(this);
        this.doCancel = this.doCancel.bind(this);

        this.state = {
            modal_opened: props.modal_opened || false,
            busy: false
        }
    }

    doCancel() {
        if (this.props.cancelAction) {
            this.props.cancelAction(this);
        }
    }

    doAction() {
        if (this.state.busy) {
            return;
        }
        this.props.action(this);
    }

    closeModal() {
        if (this.state.busy) {
            return;
        }
        this.setState({modal_opened: false});
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    toggleModal() {
        if (this.state.busy) {
            return;
        }
        this.setState({modal_opened: !this.state.modal_opened});
    }

    renderModal() {
        const childrenWithProps = React.Children.map(this.props.children, child => {
                if (child) {
                    return React.cloneElement(child)
                } else {
                    return child
                }
            }
        );

        return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal}
                   backdrop={"static"} style={this.props.modal_style}>
                <div className={"spinner-overlay " + (this.props.spinner ? "active" : "inactive")}>
                    <i className="fa fa-spinner fa-spin fa-2x spin-loader"></i>
                </div>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">{this.props.title}</h3>
                        <p>{this.props.message}</p>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr className="mt-0"/>
                    {childrenWithProps}
                </ModalBody>
                <ModalFooter>
                    {this.props.cancelAction &&
                    <Button color="primary" outline onClick={this.props.cancelAction ? this.doCancel : this.toggleModal}
                            className="btn-wide">
                        Cancel
                    </Button>}
                    {this.props.action &&
                    <Button color="primary" onClick={this.doAction} disabled={this.state.busy} className="btn-wide">
                        {this.props.action_label || this.props.label}
                    </Button>}
                </ModalFooter>
            </Modal>
        );
    }

    render() {

        return (
            <span>
                <Button onClick={this.toggleModal} color={this.props.color} outline={this.props.outline}
                        className={this.props.className}>{this.props.label}</Button>
                {this.renderModal()}
            </span>
        )
    }
}
