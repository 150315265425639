import React, {Fragment} from 'react';
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {ArrayField} from "informed";
import {TransactionHeader, TransactionRow} from "./transaction_row";

export const TransactionsTab = (props) => {
    return (
        <Card style={{minHeight: "580px"}}>
            <CardBody>
                <ArrayField field="transaction_items">
                    {({addWithInitialValue, fields}) => (
                        <Fragment>
                            <CardTitle><h4>{props.title} <Button outline color="primary" className="btn btn-xs"
                                                                 onClick={() => addWithInitialValue({
                                                                     new_record: true
                                                                 })}>+ Add Item</Button></h4></CardTitle>
                            <TransactionHeader/>
                            {fields.map(({field, key, remove}, index) => (
                                <TransactionRow key={key}
                                                index={index}
                                                field={field}
                                                fields={fields}
                                                remove={remove}
                                                formApi={props.formApi}
                                                errors={props.errors}
                                                lookups={props.lookups}
                                />
                            ))
                            }
                        </Fragment>
                    )}

                </ArrayField>
            </CardBody>
            <Row>
                <Col md="10">
                </Col>
                <Col md="2">
                    <Button color="primary" className="mt-4" onClick={props.submit}>
                        Next >>
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

