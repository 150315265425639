import React, {Component} from 'react';
import CSVUploader from "alpaca.js/dist/bulk_upload/csvUploader";
import axios from "axios";
import {crmClient} from "../crmapi";

const COLUMN_MAPPINGS = {
    examination_resolution_id: "Auth Number",
    invoice_number: "Invoice Number",
    requested_amount: "Amount",
};

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export default class InvoiceUpload extends Component {
    render() {
        return  (
            <div className="container">
                <CSVUploader
                    mappings={this.props.mappings}
                    data={this.props.data}
                    columns={COLUMN_MAPPINGS}
                    axiosInstance={axios}
                    uploadUrl="/api/invoices/bulk/"
                    fileUploadUrl="/invoice_documentation/"
                    onUpload={() => {document.location = '/invoices/upload/errors/'}}
                    crmClient={crmClient}
                />
            </div>
        )
    }
}
