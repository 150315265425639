import React from 'react';
import {Col, Row} from "reactstrap";
import DateInput from "../forms/date_input";
import FieldInput from "../forms/field_input";
import LoadingButton from "alpaca.js/dist/util/loadingButton";


const AuthorizationForm = (props) => {
    // Renders the response notes and submit button for the quote request form

    /*jshint ignore:start*/
    return (
        <Row className="mt-4">
            <Col lg="3" md="6" xs="12">
                <FieldInput
                    label="Receipt Number"
                    type="text"
                    value={props.authorization.receipt_number}
                    onChange={props.onChange.bind(null, 'receipt_number')}
                    error={props.errors.receipt_number}
                    disabled={props.inReview || props.hasVoucher}
                />
            </Col>
            <Col lg="4" md="6" xs="12">
                <DateInput
                    label="Receipt Date"
                    type="text"
                    value={props.authorization.redemption_date}
                    onChange={props.onChange.bind(null, 'redemption_date')}
                    error={props.errors.redemption_date}
                    disabled={props.inReview || props.hasVoucher}
                />
            </Col>
            {!props.inReview &&
            <Col lg="5" md="12" xs="12">
                <LoadingButton color="primary" className="mt-4 mr-1" onClick={props.onSubmit} loading={props.submitting}
                               disabled={props.hasVoucher || props.submitting}>
                    Submit
                </LoadingButton>
                <LoadingButton color="primary" className="mt-4" onClick={function (e) {
                    props.onSubmit(e, true)
                }} loading={props.submitting_invoice} disabled={props.hasVoucher || props.submitting}>
                    Submit &amp; Invoice
                </LoadingButton>
            </Col>
            }
        </Row>
    );
    /*jshint ignore:end*/
};

export default AuthorizationForm;
