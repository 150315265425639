import React, {Component, Fragment} from 'react';
import {Alert, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import {RegistrationTab} from "./registration_tab";
import {ContactTab} from "./contact_tab";
import {TransactionsTab} from "./transaction_tab";
import {Form} from 'informed';
import JWT from "../jwt_refresh";
import axios from "axios";
import {getLookups} from "./lookups";

export function flatten_errors(errors) {
    /* takes a nested error json and turns it into dot notation.  from binnacle */
    let all_errors = {};
    if (typeof (errors) === "string") {
        // if errors is a string, it's some kind of failure of the whole request, reformat it so it will
        // end up as a form level error.
        errors = {"request_failure": errors}
    }
    for (let key in errors) {
        if (_.isArray(errors[key])) {
            if (_.isString(errors[key][0])) {
                all_errors[key] = errors[key];
            } else {
                for (let index in errors[key]) {
                    if (_.isArray(errors[key][index])) {
                        all_errors[key + `[${index}]`] = errors[key][index];
                    } else {
                        let sub_errors = flatten_errors(errors[key][index]);
                        for (let key2 in sub_errors) {
                            all_errors[key + `[${index}].` + key2] = sub_errors[key2];
                        }
                    }
                }
            }
        } else if (_.isString(errors[key])) {
            all_errors[key] = errors[key];
        } else if (_.isInteger(errors[key])){
            all_errors[key] = errors[key];
        } else {
            let sub_errors = flatten_errors(errors[key]);
            for (let key2 in sub_errors) {
                all_errors[key + '.' + key2] = sub_errors[key2];
            }
        }
    }

    return all_errors;
}

const NavTab = (props) => {
    return (
        <NavItem>
            <NavLink className={classnames({active: props.active_tab === props.id})} onClick={() => {
                props.toggle(props.id);
            }}>
                {props.tab_error ? <span className="status-rejected" title="Errors Exist"></span> : ""}
                {props.title}
            </NavLink>
        </NavItem>
    )
};


const TabHeader = (props) => {
    function check_error(index) {
        let errors = props.formApi.getState().errors;
        let raw_errors = props.raw_errors;

        function check(fields) {
            for (let error in errors) {
                if (fields.indexOf(error) >= 0) {
                    return true;
                }
            }
            return false;
        }

        switch (index) {
            case 0:
                let reg_fields = ['store_number', 'salesperson_id', 'invoice_number', 'invoice_date',
                    'transaction_number', 'online_purchase', 'protection_plan_sku', 'retail_protection_price',
                    'transaction_total', 'total_billed_amount', 'tax',
                    'free_in_store_pickup', 'estimated_delivery_date', 'shipping_cost',
                    'finance_name', 'finance_value'];
                return check(reg_fields);
            case 1:
                if (raw_errors && raw_errors.transaction_items) {
                    return true;
                }
                return false;
            case 2:
                if (raw_errors) {
                    if (raw_errors.payment && typeof (raw_errors.payment) === 'string') {
                        // when there is no payment info at all.
                        return true;
                    }
                    if (raw_errors.payment && raw_errors.payment.shipping) {
                        return true;
                    }
                }
                // search for an error in the tab fields
                let other_fields = [
                    'coupon', 'opt_in_mobile', 'opt_in_email', 'checksum', 'locale'
                ];
                return check(other_fields);
        }
        return false;
    }

    return (
        <Fragment>
            {props.tabitems.map((tab, index) =>
                <NavTab key={index} {...tab} active_tab={props.active_tab} toggle={props.toggle}
                        tab_error={check_error(index)}/>)
            }
        </Fragment>
    )
};

export default class PlanForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: this.props.active_tab || 0,
            form_errors: {}, // because the "informed" does not work as expected.
            form_title: undefined,
            response_status: "#0a7b45", // Colour of the response code status
            transaction_errors: [], // because we need this for transactions tab.
            raw_errors: {}, //because it is easier to figure out if there are errors on a tab this way.
            state: null
        };
        // this form can register new plans, or update existing ones. save the method and url for submit
        if (this.props.plan_reg_url) {
            this.save_method = axios.post;
            this.save_url = this.props.plan_reg_url;
        }
        if (this.props.plan_edit_url) {
            this.save_method = axios.put;
            this.save_url = this.props.plan_edit_url;
        }

        this.toggle = this.toggle.bind(this);
        this.advancePage = this.advancePage.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.handleErrors = this.handleErrors.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.getLookups = getLookups.bind(this);
        this.advanceWithValidate = this.advanceWithValidate.bind(this);
        this.validateItems = this.validateItems.bind(this);

        // make auto refreshed token object
        this.jwt = new JWT({access_token: props.access_token, refresh_token: props.refresh_token});
    }

    componentDidMount() {
        if (this.props.onOpen) {
            this.props.onOpen(this);
        }
        // ability to create a planform with errors preloaded.
        if (this.props.response) {
            setTimeout(function () {
                // informed weirdness.. must delay setting of errors for a bit.
                this.clearErrors();
                if (this.props.response.errors) {
                    this.handleErrors(this.props.response.errors);
                } else if (this.props.response.warnings) {
                    this.handleErrors(this.props.response.warnings);
                }
            }.bind(this), 250);
        }
        this.getLookups()

    }



    toggle(tab) {
        // change the tab
        if (this.state.active_tab !== tab) {
            window.scrollTo(0, 200);
            this.setState({
                response_status: this.state.response_status,
                active_tab: tab,
                errors: null
            });
        }
    }

    setFormApi(formApi) {
        this.formApi = formApi;
    }

    validateItems(){
        let state = this.formApi.getState();
        if (state.values){
            if (state.values.transaction_items){
                for (let item in state.values.transaction_items){
                    let values = state.values.transaction_items[item]
                    if (values.item_category || values.item_type || values.item_material){
                        if (values.item_category && values.item_type && values.item_material){
                            return true;
                        }
                        else {
                            alert("Please select all item details (Category, Type, Material) for " + values.description)
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    advancePage() {
        // next tab
        this.toggle(this.state.active_tab + 1);
    }

    advanceWithValidate() {
        if(this.validateItems()){
            this.advancePage();
        }
    }

    handleErrors(errors) {

        let all_errors = flatten_errors(errors);
        let form_errors = {};
        this.setState({raw_errors: errors});
        for (let error in all_errors) {
            if (error == "payment.shipping") {
                all_errors[error] = "Shipping contact info is required";
            }
            if (this.formApi.fieldExists(error)) {
                this.formApi.setError(error, all_errors[error]);
            } else {
                if (error.startsWith("transaction_items[")) {
                    let formatted={};
                    let transaction_items = errors.transaction_items;
                    // format the errors so they can be easily retrieved by line number
                    for (let err in transaction_items){
                        let item=formatted["item_"+(transaction_items[err].line-1)] || {};
                        let field = transaction_items[err]["field"]
                        let message= transaction_items[err]["message"]
                        item[field] = message
                        formatted["item_"+(transaction_items[err].line-1)] = item;
                    }
                    this.setState({"transaction_errors": formatted});
                } else {
                    form_errors[error] = all_errors[error];
                }
            }
        }
        this.setState({
            form_errors: form_errors
        })
    }

    clearErrors() {
        // manually clear the errors
        let errors = flatten_errors(this.formApi.getState().errors);
        for (let error in errors) {
            this.formApi.setError(error, undefined);
        }
        this.setState({
            form_errors: {},
            transaction_errors: []
        });
    }

    submitForm() {
        let data = this.formApi.getState().values;
        let form_errors = this.validateForm(data);
        if (Object.keys(form_errors).length > 0) {
            return;
        }
        // clean out null transaction items, set default class if missing
        if (data.transaction_items) {
            let newitems = Array();
            for (let item in data.transaction_items) {
                let value = data.transaction_items[item];
                if (value !== null) {
                    if (value.item_class === null || value.item_class === undefined){
                        value.item_class = "furniture";
                    }
                    /* it is possible a crm_id is in there, if this plan was edited and item not edited.
                       The form typically removes the crm_id, it's an extra field.

                       But because of the way this works, there is no form until you pop it up.
                       So, we have to remove it manually */
                    if (value["crm_id"]){
                        delete value["crm_id"];
                    }
                    /* wholesale_price is not really required here. so we're putting zero to bypass the requirement */
                    if (value["wholesale_price"] === undefined || value["wholesale_price"] === ""){
                        value["wholesale_price"] = 0.0;
                    }
                    newitems.push(value);
                }
            }
            data.transaction_items = newitems;
        }
        // put in values of "hidden" fields. which do not exist in informed.
        data["distributor_number"] = this.props.registration["distributor_number"];
        data["retailer_number"] = this.props.registration["retailer_number"];

        this.save_method(this.save_url, data, {headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}}).then((response) => {
            response.status= "#0a7b45";
            this.setState({
                form_title: response.data.title + ": " + response.data.detail.plan_number,
                response_status: response.status,
                active_tab: 1
            });
            this.clearErrors();
            if (this.props.onSubmit){
                this.props.onSubmit()
            }
            window.scrollTo(0,200);
            this.formApi.reset();
        }).catch((error) => {
            window.scrollTo(0,0);
            this.state.response_status="red";
            this.setState({form_title: error.response.data.title});
            this.handleErrors(error.response.data.errors);
        })
    }

    validateForm(values) {
        // Handle form level errors
        this.clearErrors();
        let form_errors = {};

        if (!values.payment || (values.payment && values.payment.shipping && !values.payment.shipping.customer_info)) {
            form_errors["values.payment.shipping.customer_info"] = "Shipping Info is Required"
        }

        // Manually do dropdown validation, because BootstrapReactSelect does not properly handle the validate= property
        // the required property lingo is so all the fields act the same.
        if (values.online_purchase === undefined) {
            this.formApi.setError("online_purchase", "'online_purchase' is a required property");
        }
        if (!values.payment || !values.payment.shipping || values.payment.shipping.free_in_store_pickup === undefined) {
            this.formApi.setError("payment.shipping.free_in_store_pickup", "'free_in_store_pickup' is a required property'");
        }
        if (values.rent_to_own === undefined) {
            this.formApi.setError("rent_to_own", "'rent_to_own' is a required property");
        }
        if ((values.payment && values.payment.shipping && values.payment.shipping.customer_info) && values.payment.shipping.customer_info.state === undefined) {
            this.formApi.setError("payment.shipping.customer_info.state", "'state' is a required property");
        }
        if (values.payment && values.payment.billing && !values.payment.billing.pay_same_as_ship) {
            if (!values.payment.billing || !values.payment.billing.customer_info || values.payment.billing.customer_info.state === undefined) {
                this.formApi.setError("payment.billing.customer_info.state", "'state' is a required property");
            }
        }
        if (values.transaction_items){
            let items = values.transaction_items;
            for(let item in items){
                if(items[item].item_category || items[item].item_type || items[item].item_material ){
                    console.log("Transaction item validation");
                    this.formApi.setError("transaction_items", "Please fill all the item details.");
                }
            }
        }

        this.setState({form_errors: form_errors});
        return form_errors;
    }

    render() {
        let registration = this.props.registration;
        let tab_items = [
            {
                id: 1,
                title: "Registration Details",
                form: RegistrationTab,
                submit: this.advancePage
            },
            {
                id: 2,
                title: "Item Details",
                form: TransactionsTab,
                submit: this.advanceWithValidate
            },
            {
                id: 3,
                title: "Contact Info",
                form: ContactTab,
                submit: this.submitForm
            },
        ];
        // log all state changes: <Form onChange={formState => console.log(formState)>
        return (
            <div className="plan-reg-form">
                <Form getApi={this.setFormApi.bind(this)} initialValues={registration}
                      onSubmit={this.submitForm}>
                    {({formApi, formState}) => (
                        <Fragment>
                            <Row>
                                <Col className="pb-2" sm="6">
                                    <h5 style={{color: this.state.response_status}}>{this.state.form_title}</h5>
                                </Col>
                            </Row>
                            <Nav tabs>
                                <TabHeader tabitems={tab_items} active_tab={this.state.active_tab}
                                           toggle={this.toggle} formApi={formApi}
                                           raw_errors={this.state.raw_errors}/>
                            </Nav>
                            <TabContent activeTab={this.state.active_tab.toString()}>
                                {tab_items.map(({id, title, form: FormClass, submit, data}, index) => (
                                    <TabPane key={index} tabId={id.toString()}>
                                        <FormClass key={index} title={title} submit={submit} formApi={formApi}
                                                   formState={formState}
                                                   errors={this.state.transaction_errors}
                                                   lookups={this.state.lookups}
                                                   closeModal={this.props.closeModal}
                                        />
                                        { /* uncomment to see the state data! <pre>{JSON.stringify(formState.values, null,4)}</pre> */}
                                    </TabPane>
                                ))}
                            </TabContent>
                        </Fragment>
                    )}
                </Form>
            </div>
        )
    }
}


// PlanForm.propTypes = {
//     registration: PropTypes.object.isRequired,
// };

