import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input} from 'reactstrap';
import classNames from 'classnames';


export default class FieldInput extends React.Component {
    constructor(props) {
        super(props);
    }

    renderInput() {
        let {value, error, label, ...rest} = this.props;
        if (value === null || value === undefined) {
            value = '';
        }
        return (
            <Input
                value={value}
                className={classNames({'is-invalid': error})}
                {...rest}
            />
        )
    }

    render() {
        return (
            <FormGroup className={classNames({'is-invalid': this.props.error})}>
                {this.props.label &&
                    <Label>{this.props.label}</Label>
                }
                {this.renderInput()}
                {this.props.error &&
                    <span className="invalid-feedback">{this.props.error}</span>
                }
            </FormGroup>
        );
    }
}

FieldInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
};

FieldInput.defaultProps = {
    label: '',
    type: 'text',
};
