import React from 'react';
import FieldInput from './field_input';
import Select2 from './select2';
import PropTypes from "prop-types";


export default class SelectInput extends FieldInput {
    constructor(props) {
        super(props);
    }

    renderInput() {
        let {label, error, onChange, choices, ...rest} = this.props;

        return <Select2
            onSelect={onChange}
            data={choices}
            {...rest}
        />
    }
}

SelectInput.propTypes = {
    label: PropTypes.string,
    emptyLabel: PropTypes.string,
};

SelectInput.defaultProps = {
    label: '',
    emptyLabel: '--------'
};
