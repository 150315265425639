import React, {Fragment} from 'react';
import { Table, Row, Col, Card, CardBody} from "reactstrap";
import FieldInput from "../forms/field_input";
import SelectInput from "../forms/select_input";
import YesNoInput from "../forms/yes_no_input";


const PartRow = (props) => {
    // console.log('rendering part row', props);
    let qrc_index = props.quote_request_claim_index;
    let part_index = props.part_index;

    let changeHandler = (field) => {
        return props.onPartChange.bind(null, qrc_index, part_index, field);
    };

    let shared_props = {disabled: !props.available || props.readOnly};

    /*jshint ignore:start*/
    return (
        <>
            {props.part_index !== 0 && <hr/>}

            <strong className="mb-2 d-block">{props.description}</strong>

            <Row>
                <Col lg={3} sm={4}>
                    <YesNoInput onChange={changeHandler('available')} value={props.available} disabled={props.readOnly} label="Available?" />
                </Col>

                <Col lg={3} sm={4}>
                    <SelectInput minimumResultsForSearch={20} onChange={changeHandler('available_time_frame')} label="Availability Time Frame"
                        value={props.available_time_frame} disabled={!props.available || props.readOnly}
                        choices={[
                            ["in warehouse", "In Warehouse"],
                            ["2 weeks", "2 Weeks"],
                            ["30 days", "30 Days"],
                            ["45 days", "45 Days"],
                            ["60 days", "60 Days"],
                            ["90 days", "90 Days"],
                            ["more than 90 days", "more than 90 Days"],
                        ]}
                    />
                </Col>

                <Col lg={3} sm={4}>
                    <FieldInput onChange={changeHandler('part_number')} value={props.part_number} label="Part Number" {...shared_props} />
                </Col>

                <Col lg={3} sm={4}>
                    <FieldInput onChange={changeHandler('wholesale_price')} value={props.wholesale_price} label="Part Price" type="number" {...shared_props} />
                </Col>

                <Col lg={3} sm={4}>
                    <FieldInput onChange={changeHandler('delivery_price')} value={props.delivery_price} label="Estimated Shipping Cost" type="number" {...shared_props} />
                </Col>

                <Col lg={3} sm={4}>
                    <YesNoInput onChange={changeHandler('installation_available')} value={props.installation_available} label="Installation Available?" {...shared_props} />
                </Col>

                <Col lg={3} sm={4}>
                    <FieldInput onChange={changeHandler('installation_price')} value={props.installation_price} type="number" label="Installation Cost"
                        disabled={!props.available || !props.installation_available || props.readOnly}
                    />
                </Col>

            </Row>
        </>
    );
    /*jshint ignore:end*/

};


const AuthorizationQuote = (props) => {
    // console.log("AuthorizationQuote props", props);
    let qrc = props.quote_request_claim;
    let index = props.quote_request_claim_index;

    let changeHandler = (field) => {
        return props.onChange.bind(null, index, field);
    };

    let shared_props = {disabled: props.readOnly};

    /*jshint ignore:start*/
    return (
        <Fragment>
            <div className="d-flex">
                <span className="h5 d-inline-block mr-4">Authorization Quote</span>
            </div>
            <Row>
                <Col md={4} lg={4}>
                    <YesNoInput label="Item Available" value={qrc.item_available} {...shared_props}
                                onChange={changeHandler('item_available')} />
                </Col>
                <Col md={4} lg={3}>
                    <FieldInput label="Retail Price" onChange={changeHandler('item_retail_price')}
                        value={qrc.item_retail_price} type="number" {...shared_props} />
                </Col>
                <Col md={4} lg={3}>
                    <FieldInput label="Wholesale Price" onChange={changeHandler('item_wholesale_price')}
                        value={qrc.item_wholesale_price} type="number" {...shared_props} />
                </Col>
            </Row>

            <Row>
                <Col md={4} lg={4}>
                    <YesNoInput label="Did the customer pay for delivery on original purchase?" onChange={changeHandler('customer_paid_delivery')}
                                value={qrc.customer_paid_delivery} {...shared_props} />
                </Col>
                {props.delivery_optional !== false &&
                    <Col md={4} lg={3}>
                        <FieldInput label="Original Delivery Fee" onChange={changeHandler('item_delivery_price')}
                                    value={qrc.item_delivery_price} type="number"
                                    disabled={props.readOnly || !qrc.customer_paid_delivery}/>
                    </Col>
                }
            </Row>
        </Fragment>
    );
    /*jshint ignore:end*/
};


export const QuoteRequestClaim = (props) => {
    // console.log('request claim props', props);
    // console.log('parts', props.parts);

    /*jshint ignore:start*/
    return (
        <Fragment>
            <Card className="bg-secondary mb-4">
                <CardBody>
                    <AuthorizationQuote
                        quote_request_claim={props.quote_request_claim}
                        quote_request_claim_index={props.quote_request_claim_index}
                        delivery_optional={props.delivery_optional}
                        onChange={props.onChange}
                        readOnly={props.readOnly}
                    />
                </CardBody>
            </Card>
            <Card className="bg-secondary mb-4">
                <CardBody>
                    <div className="d-flex">
                        <span className="h5 d-inline-block mr-4">Parts Quote</span>
                    </div>
                    {props.quote_request_claim.parts.length > 0 &&
                        <>
                            {props.quote_request_claim.parts.map((part, index) =>
                                <PartRow
                                    {...part}
                                    part_index={index}
                                    quote_request_claim_index={props.quote_request_claim_index}
                                    onPartChange={props.onPartChange}
                                    readOnly={props.readOnly}
                                    key={`c_${props.quote_request_claim_index}_part_${index}`}
                                />
                            )}
                        </>
                    }
                </CardBody>
            </Card>
        </Fragment>
    );
    /*jshint ignore:start*/
};
