import React, {Component} from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";

export default class JWT extends Component {
    /* this stores tokens in localstorage, and uses an interval timer to keep the access token refreshed */
    constructor(props) {
        super(props);
        this.get_access_token = this.get_access_token.bind(this);
        this.refresh_access_token = this.refresh_access_token.bind(this);

        this.name = props.name || "tokens";
        localStorage.setItem(this.name, JSON.stringify({
            refresh_token: props.refresh_token,
            access_token: props.access_token,
        }));

        setInterval(this.refresh_access_token, 30000);
    };

    get_access_token() {
        let tokens = localStorage.getItem(this.name);
        if (!tokens) {
            return null;
        }
        tokens = JSON.parse(tokens);
        return tokens.access_token;
    }

    refresh_access_token() {
        let tokens = localStorage.getItem(this.name);
        if (!tokens) {
            return;
        }
        tokens = JSON.parse(tokens);

        if (tokens.access_token) {
            let decoded = jwt_decode(tokens.access_token);
            let timestamp = Math.floor(Date.now() / 1000)
            /* when 2.5 minutes to expiring, refresh the token*/
            if (timestamp > decoded.exp-150) {
                let data = {
                    refresh: tokens.refresh_token
                };
                axios.post('/api/jwt_refresh/', data).then((response) => {
                    tokens.access_token = response.data.access;
                    localStorage.setItem(this.name, JSON.stringify(tokens));
                }).catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.setItem(this.name, '');
                    }
                });
            }
        }
    }
}

