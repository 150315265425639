import axios from "axios";

function formatStorePlans(lookup_data) {
    // arrange the plan lookups by store_number for retrieval by dropdown value
    let data = Object();
    for (let store of lookup_data.stores) {
        let plans = Array();
        store.available_plans.sort();
        for (let plan of store.available_plans) {
            plans.push({value: plan, label: plan})
        }
        data[store.store_number] = plans;
    }
    return data;
}

function formatStores(lookup_data) {
    // sort and format the stores dropdown values
    let data = Array();
    lookup_data.stores.sort(function (a, b) {
        return parseInt(a.store_number) - parseInt(b.store_number)
    });
    for (let store of lookup_data.stores) {
        data.push({
            value: store.store_number,
            label: `#${store.store_number} ${store.name}, ${store.state}`
        });
    }
    return data;
}

function formatCategoryTypes(lookup_data){
        let category_types = Object();
        lookup_data.categories.forEach((c) => {
            category_types[c.value] = c.types
        });
        return category_types;
}

function formatStateChoices(state_choices) {
    let states = Array();

    state_choices.forEach(([value, label]) => {
        states.push({value: value, label: label})
    });
    return states;
}



export function getLookups() {
    // load lookups. this function needs to be bound to a component.
    axios.get(this.props.lookup_url, {headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}}).then((response) => {
        let lookup_data = response.data.detail;
        let lookups = {
            store_plans: formatStorePlans(lookup_data),
            stores: formatStores(lookup_data),
            category_types: formatCategoryTypes(lookup_data),
            state_choices: formatStateChoices(this.props.state_choices),
            types: lookup_data.types,
            categories: lookup_data.categories,
            materials: lookup_data.materials
        };
        this.setState({
            lookups: lookups
        });
    }).catch((error) => {
        console.log("Error loading lookups");
        console.log(error);
    });
}
