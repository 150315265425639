import { ModalButton } from "./modal_button";
import PlanForm from "./plan_form";
import React, { Fragment } from "react";
import axios from "axios";
import JWT from "../jwt_refresh";

export default class PlanEditButton extends React.Component {
    constructor(props) {
        super(props);
        this.onOpen = this.onOpen.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.cancelPlan = this.cancelPlan.bind(this);
        this.getData = this.getData.bind(this);
        this.jwt = new JWT({access_token: props.access_token, refresh_token: props.refresh_token});
        this.state = {
            registration: null,
            spinner: false,
            error: ""
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.setState({spinner: true}, function () {
            // load lookups
            axios.get(this.props.plan_edit_url, {headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}}).then((response) => {
                this.setState({
                    registration: response.data.detail,
                    spinner: false,
                    error: ""
                });
            }).catch((error) => {
                let errorMsg = "";
                console.log("Error Loading plan");
                if (error.response) {
                    if(error.response.data.response){
                        let errors = error.response.data.errors;
                        console.log(JSON.stringify(errors));

                        let keys = Object.keys(errors);
                        keys.forEach((key, index) => {
                            errorMsg = errorMsg + `${key}: ${errors[key]}`;
                        });
                    }
                } else {
                    errorMsg = JSON.stringify(error);
                }
                this.setState({spinner: false, error: errorMsg});
            });
        });
    }

    cancelPlan(modalform) {
        this.setState({spinner: true}, function () {
            axios.put(this.props.plan_void_url, {}, {headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}}).then((response) => {
                this.setState({spinner: false});
                window.location.reload();
            }).catch((error) => {
                let errorMsg = "";

                console.log("Error Cancelling plan");
                if (error.response) {
                    let errors = error.response.data.errors;
                    console.log(JSON.stringify(errors));

                    let keys = Object.keys(errors);
                    keys.forEach((key, index) => {
                        errorMsg = errorMsg + `${key}: ${errors[key]}`
                    })
                } else {
                    errorMsg = JSON.stringify(error);
                }
                this.setState({spinner: false, error: errorMsg});
            });
        })
    }

    onOpen() {
        if (!this.state.registration) {
            alert("Unable to load plan");
        }
    }

    onSubmit(modalform) {
        window.location.reload();
    }

    noOp() {

    }

    render() {
        return (
            <Fragment>
                <ModalButton outline color="danger" className="btn btn-xs mr-1 mb-1"
                             title="Edit Plan"
                             label="Edit Plan"
                             modal_style={{maxWidth: "1024px"}}
                             spinner={this.state.spinner}
                >
                    <PlanForm
                        access_token={this.props.access_token}
                        refresh_token={this.props.refresh_token}
                        active_tab={this.props.active_tab}
                        lookup_url={this.props.lookup_url}
                        plan_edit_url={this.props.plan_edit_url}
                        state_choices={this.props.state_choices}
                        registration={this.state.registration}
                        onOpen={this.onOpen}
                        onSubmit={this.onSubmit}
                    />
                    <div>{this.state.error}</div>
                </ModalButton>
                <ModalButton outline color="danger" className="btn btn-xs mr-1 mb-1"
                             action={this.cancelPlan}
                             spinner={this.state.spinner}
                             title="Void Plan"
                             label="Void Plan"
                             action_label={"Void Plan"}>
                    <h5> Void this plan? </h5>
                    <div>{this.state.error}</div>
                </ModalButton>

            </Fragment>)
    }
}
