import React, { useState } from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';

export const HelpPopover = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <>
      <div id={props.id}>
          <i className={"fas fa-question"}></i>
      </div>
      <Popover trigger="legacy" placement="bottom" isOpen={popoverOpen} target={props.id} toggle={toggle}>
        <PopoverHeader>{props.title}</PopoverHeader>
        <PopoverBody>{props.content}</PopoverBody>
      </Popover>
    </>
  );
};

HelpPopover.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    id: PropTypes.string
};
