import {Col, Row} from "reactstrap";
import {ModalButton} from "./modal_button";
import {TransactionItemForm} from "./transaction_item_form";
import React, {Component, Fragment} from "react";

const DisplayField = (props) => {
    /* display a field */
    // protect against glitch during add operation
    if (props.data === undefined || props.field === undefined) {
        return null;
    }
    return (
        <div className="mt-2">
            {props.errors ? <span className="status-rejected" title="Errors Exist"></span> : ""}
            {props.data[props.field]}
        </div>
    );
};

export const TransactionHeader = (props) => {
    /* display transaction header row */
    return (<Row className="mb-2">
        <Col sm="3">
            <strong>Description</strong>
        </Col>
        <Col sm="2">
            <strong>Transaction Number</strong>
        </Col>
        <Col sm="2">
            <strong>Manufacturer SKU</strong>
        </Col>
        <Col sm="1">
            <strong>Qty</strong>
        </Col>
        <Col sm="2">
            <strong>Retail Price</strong>
        </Col>
    </Row>);
};

export class TransactionRow extends Component {
    /* display a row, and buttons for editing and deleting */
    constructor(props) {
        super(props);
        this.saveModal = this.saveModal.bind(this);
        this.checkCancel = this.checkCancel.bind(this);
        this.delete = this.delete.bind(this);
        this.setModalFormApi = this.setModalFormApi.bind(this);

        /* we have to initialize the transaction_item arrayfield from initial values */
        let data = this.props.formApi.getValue("transaction_items");
        let initial = this.props.fields[this.props.index].initialValue;
        if (data) {
            data[this.props.index] = initial;
        } else {
            if (initial) {
                data = [initial];
            } else {
                data = [];
            }
        }
        // this little hack allows us to pop up the modal on new records
        this.new_record = (initial && initial.new_record) || false;
        if (this.new_record) {
            delete initial["new_record"];
        }
        this.props.formApi.setValue("transaction_items", data);
    }

    delete() {
        /* remove visual */
        this.props.remove();
        /* remove from form state */
        let data = this.props.formApi.getValue("transaction_items");
        data.splice(this.props.index, 1);
        this.props.formApi.setValue("transaction_items", data);
    }

    setModalFormApi(modalFormApi) {
        this.modalFormApi = modalFormApi;
    }

    checkCancel(modalform) {
        // if the modal is a new item, then delete the item if canceled
        if (this.new_record) {
            let data = this.props.formApi.getValue("transaction_items");
            data.splice(this.props.index, 1);
            this.props.formApi.setValue("transaction_items", data);
        } else {
            modalform.toggleModal();
        }
    }

    saveModal(modalform) {
        this.modalFormApi.submitForm();
        let modal_data = JSON.parse(JSON.stringify(this.modalFormApi.getState().values));
        let data = this.props.formApi.getValue("transaction_items");
        data[this.props.index] = modal_data;
        this.props.formApi.setValue("transaction_items", data);
        modalform.toggleModal();
        // this.new_record = false;
    }


    render() {
        let transaction_items = this.props.formApi.getValue("transaction_items");
        let data;
        if (transaction_items) {
            data = transaction_items[this.props.index];
        }

        if (data) {
            if(this.new_record){
                return (
                <Fragment>
                    <Row>
                        <Col sm="3">
                            <DisplayField field="description" data={data} errors={this.props.errors[this.props.index]}/>
                        </Col>
                        <Col sm="2">
                            <DisplayField field="item_transaction_number" data={data}/>
                        </Col>
                        <Col sm="2">
                            <DisplayField field="manufacturer_sku" data={data}/>
                        </Col>
                        <Col sm="1">
                            <DisplayField field="quantity" data={data}/>
                        </Col>
                        <Col sm="2">
                            <DisplayField field="retail_price" data={data}/>
                        </Col>
                        <Col sm="2">
                            {!data.return_invoice &&
                            <div className="transaction-item-actions">
                                <ModalButton outline color="primary" className="btn btn-xs mb-1"
                                             action={this.saveModal} title="Edit Transaction Item"
                                             label="Edit" action_label="Save"
                                             modal_style={{maxWidth: "700px"}}
                                             modal_opened={this.new_record}
                                             cancelAction={this.checkCancel}
                                >
                                    <TransactionItemForm data={data} errors={this.props.errors["item_"+this.props.index]}
                                                         onSubmit={this.saveModal}
                                                         setFormApi={this.setModalFormApi}
                                                         lookups={this.props.lookups}
                                    />
                                </ModalButton>
                                <ModalButton outline color="danger" className="btn btn-xs mr-1 mb-1"
                                             action={this.delete} title="Delete Transaction Item?"
                                             label="Delete"
                                />
                            </div>}
                            {data.return_invoice && <span>Returned on<br/>Inv# {data.return_invoice}</span>}
                        </Col>
                    </Row>
                </Fragment>
            )
            } else {
                return (
                    <Fragment>
                        <Row>
                            <Col sm="3">
                                <DisplayField field="description" data={data}
                                              errors={this.props.errors[this.props.index]}/>
                            </Col>
                            <Col sm="2">
                                <DisplayField field="item_transaction_number" data={data}/>
                            </Col>
                            <Col sm="2">
                                <DisplayField field="manufacturer_sku" data={data}/>
                            </Col>
                            <Col sm="1">
                                <DisplayField field="quantity" data={data}/>
                            </Col>
                            <Col sm="2">
                                <DisplayField field="retail_price" data={data}/>
                            </Col>
                            <Col sm="2">
                                {!data.return_invoice &&
                                    <div className="transaction-item-actions">
                                        <ModalButton outline color="primary" className="btn btn-xs mb-1"
                                                     action={this.saveModal} title="Edit Transaction Item"
                                                     label="Edit" action_label="Save"
                                                     modal_style={{maxWidth: "700px"}}
                                                     modal_opened={this.new_record}
                                                     cancelAction={this.checkCancel}
                                        >
                                            <TransactionItemForm data={data}
                                                                 errors={this.props.errors["item_" + this.props.index]}
                                                                 onSubmit={this.saveModal}
                                                                 setFormApi={this.setModalFormApi}
                                                                 lookups={this.props.lookups}
                                            />
                                        </ModalButton>
                                    </div>}
                                {data.return_invoice && <span>Returned on<br/>Inv# {data.return_invoice}</span>}
                            </Col>
                        </Row>
                    </Fragment>
                )
            }
        } else {
            return null;
        }
    }
}
