import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import classNames from 'classnames';
import { FormGroup } from 'reactstrap';


const RadioInput = (props) => {

    let id = uniqueId("yes-no-");
    let {label_class, label, input_class, ...rest} = props;

    return (
        <div className={props.wrapper_class}>
            <input type="radio" id={id} value={label==="Yes"} className={input_class} onChange={props.onChange}
                   {...rest} />
            <label className={label_class} htmlFor={id}>{label}</label>
        </div>
    )
};

export default class YesNoInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            yes_id: uniqueId("radio-yes-"),
            no_id: uniqueId("radio-no-"),
            input_name: uniqueId("radio-yes-no-"),
        };
    }

    render() {
        let { error, light, pullRight, pullLeft, bold, layout, ...rest } = this.props;

        let control_wrapper_class = classNames(
            "custom-control", "custom-radio", {"custom-control-light": light}, {"pull-right": pullRight},
            {"pull-left": pullLeft}
        );

        let yes_control_wrapper_class = classNames(control_wrapper_class);
        let radio_wrapper_class = "mr-2";
        if (layout === 'horizontal') {
            yes_control_wrapper_class = classNames(control_wrapper_class, "mr-3");
            radio_wrapper_class = classNames("d-flex", "mt-2");
        }

        let group_label = (this.props.children) ? this.props.children : this.props.label;

        let radio_props = {
            name: this.state.input_name,
            input_class: classNames("custom-control-input", {'is-invalid': error}),
            label_class: classNames("custom-control-label", {"font-weight-bold": bold}),
            onChange: this.props.onChange,
            disabled: this.props.disabled,
        };

        return (
            <FormGroup className={classNames({'is-invalid': error})}>
                {group_label && <label>{group_label}</label>}
                <div className={radio_wrapper_class}>
                    <RadioInput
                        wrapper_class={yes_control_wrapper_class}
                        label="Yes"
                        checked={this.props.value===true}
                        {...radio_props}
                    />

                    <RadioInput
                        wrapper_class={control_wrapper_class}
                        label="No"
                        checked={this.props.value===false}
                        {...radio_props}
                    />
                </div>
                {error && <span className="invalid-feedback">{error}</span>}
            </FormGroup>
        );
    }
}

YesNoInput.propTypes = {
    label: PropTypes.string,
    light: PropTypes.bool,
    bold: PropTypes.bool,
    layout: PropTypes.oneOf(['horizontal', 'vertical']),
};

YesNoInput.defaultProps = {
    label: '',
    light: false,
    bold: false,
    layout: 'horizontal',
};
