import { hot } from 'react-hot-loader/root';

export function register(name, component) {
    window[name] = hot(component);
}

require('./libs');
require('./config');

import { styles } from '../css/style.scss';


import FAQ from './faq/faq';
import Contact from './contact/contact';
import QuoteRequest from './quotes/quote_request';
import AuthorizationRequest from './authorizations/authorization_request';
import ClaimDetail from './claims/claim_detail';
import ClaimDetailExtra from './claims/claim_detail_extra';
import PartOrder from './part_orders/part_orders';
import InvoiceDetail from './invoices/invoiceDetail';
import { RetailerInteractionsPanel } from "./interactions/retailer_interactions_panel";
import InvoiceUpload from "./invoices/invoiceUpload";
import InvoiceUploadErrors from "./invoices/invoiceUploadErrors";
import BulkUploadCountButton from "alpaca.js/dist/bulk_upload/bulkUploadCountButton";
import TableCheckboxActionButton from "alpaca.js/dist/util/tableCheckboxActionButton";
import TableCheckboxSelectAllHeader from "alpaca.js/dist/util/tableCheckboxSelectAllHeader";
import ApiBadgeCount from "alpaca.js/dist/util/apiCountBadge";
import RetailerSignup from "./retailers/signup";
import PlanForm from "./plans/plan_form";
import ErrorFix from "./plans/error_fix";
import PlanItems from "./plans/plan_items";
import PlanEditButton from "./plans/plan_edit_button";

register('FAQ', FAQ);
register('Contact', Contact);
register('QuoteRequest', QuoteRequest);
register('AuthorizationRequest', AuthorizationRequest);
register('ClaimDetail', ClaimDetail);
register('ClaimDetailExtra', ClaimDetailExtra);
register('PartOrder', PartOrder);
register('InvoiceDetail', InvoiceDetail);
register('RetailerInteractionsPanel', RetailerInteractionsPanel);
register('RetailerSignup', RetailerSignup);
register('TableCheckboxActionButton', TableCheckboxActionButton);
register('InvoiceUpload', InvoiceUpload);
register('InvoiceUploadErrors', InvoiceUploadErrors);
register('BulkUploadCountButton', BulkUploadCountButton);
register('TableCheckboxSelectAllHeader', TableCheckboxSelectAllHeader);
register('ApiBadgeCount', ApiBadgeCount);
register('PlanForm', PlanForm);
register( 'ErrorFix', ErrorFix);
register( 'PlanItems', PlanItems);
register("PlanEditButton", PlanEditButton);
